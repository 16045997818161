/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { City } from '../models/City';
import type { Country } from '../models/Country';
import type { Language } from '../models/Language';
import type { Region } from '../models/Region';
import type { SpecializationAreaMap } from '../models/SpecializationAreaMap';
import type { TimeZone } from '../models/TimeZone';
import type { ToolResource } from '../models/ToolResource';
import type { University } from '../models/University';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DictionariesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Search for tools
   * @param type
   * @param specializations
   * @param languages An array of tool IDs
   * @param query A search string to be used for tools lookup in name, slug, and website url fields
   * @param userId Geecko user ID
   * @returns any OK
   * @throws ApiError
   */
  public getApiDictionariesTools(
    type?: any,
    specializations?: any,
    languages?: any,
    query?: any,
    userId?: any,
  ): CancelablePromise<{
    data?: Array<ToolResource>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/dictionaries/tools',
      query: {
        type: type,
        specializations: specializations,
        languages: languages,
        query: query,
        user_id: userId,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Get a list of countries
   * @param query Search phrase
   * @returns any OK
   * @throws ApiError
   */
  public getApiDictionariesCountries(query?: any): CancelablePromise<{
    data?: Array<Country>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/dictionaries/countries',
      query: {
        query: query,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Get a list of languages
   * @returns any OK
   * @throws ApiError
   */
  public getApiDictionariesLanguages(): CancelablePromise<{
    data?: Array<Language>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/dictionaries/languages',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Get a list of regions
   * @param query Search phrase
   * @returns any OK
   * @throws ApiError
   */
  public getApiDictionariesRegions(query?: any): CancelablePromise<{
    data?: Array<Region>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/dictionaries/regions',
      query: {
        query: query,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Get a list of cities
   * @param query Search phrase
   * @returns any OK
   * @throws ApiError
   */
  public getApiDictionariesCities(query?: any): CancelablePromise<{
    data?: Array<City>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/dictionaries/cities',
      query: {
        query: query,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Get a list of universities
   * @param query Search phrase
   * @param cityId
   * @returns any OK
   * @throws ApiError
   */
  public getApiDictionariesUniversities(
    query?: any,
    cityId?: any,
  ): CancelablePromise<{
    data?: Array<University>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/dictionaries/universities',
      query: {
        query: query,
        city_id: cityId,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Get a list of timezones
   * @returns any OK
   * @throws ApiError
   */
  public getApiDictionariesTimezones(): CancelablePromise<{
    data?: Array<TimeZone>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/dictionaries/timezones',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Get specializations map
   * @returns any OK
   * @throws ApiError
   */
  public getApiDictionariesSpecializationsMap(): CancelablePromise<{
    data?: Array<SpecializationAreaMap>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/dictionaries/specializations-map',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }
}
