import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useSelector } from 'react-redux';

import { authModuleSelectors } from '@/entities/authorization';
import { AppClient } from '@/shared/api';
import { UtilityApiClient } from '@/shared/api-utility';
import { selectLocale } from '@/store';
import { ENV_SERVER_URL } from '@/utils/constants';

const config: AxiosRequestConfig = {
  baseURL: ENV_SERVER_URL,
  withCredentials: true,
};

export const useApi = (): AxiosInstance => {
  const impersonationToken = useSelector(authModuleSelectors.impersonationToken);

  config.headers = {
    Accept: 'application/json',
    'Accept-Language': useSelector(selectLocale),
  };

  if (impersonationToken || process.env.NEXT_PUBLIC_BEARER_TOKEN) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${impersonationToken || process.env.NEXT_PUBLIC_BEARER_TOKEN}`,
    };
  }

  return axios.create(config);
};

type Headers = Record<string, string>;

export const createGeneratedApi = (locale: string, impersonationToken?: string): AppClient => {
  const headers: Headers = {
    'Accept-Language': locale,
  };

  if (impersonationToken || process.env.NEXT_PUBLIC_BEARER_TOKEN) {
    headers.Accept = 'application/json';
    headers.Authorization = `Bearer ${impersonationToken || process.env.NEXT_PUBLIC_BEARER_TOKEN}`;
  }

  return new AppClient({
    BASE: ENV_SERVER_URL,
    HEADERS: headers,
    WITH_CREDENTIALS: true,
  });
};

export const useGeneratedApi = (): AppClient =>
  createGeneratedApi(useSelector(selectLocale), useSelector(authModuleSelectors.impersonationToken));

export const useUtilityApi = (): UtilityApiClient => {
  const headers: Headers = {
    'Accept-Language': useSelector(selectLocale),
  };

  if (process.env.NEXT_PUBLIC_BEARER_TOKEN) {
    headers.Accept = 'application/json';
    headers.Authorization = `Bearer ${process.env.NEXT_PUBLIC_BEARER_TOKEN}`;
  }

  return new UtilityApiClient({
    BASE: process.env.NEXT_PUBLIC_UTILITY_API_ROOT,
    HEADERS: headers,
    WITH_CREDENTIALS: true,
  });
};
