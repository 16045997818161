/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CandidateList } from '../models/CandidateList';
import type { CandidateListResource } from '../models/CandidateListResource';
import type { CandidateResource } from '../models/CandidateResource';
import type { ResourceLinks } from '../models/ResourceLinks';
import type { ResourceMeta } from '../models/ResourceMeta';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MatchingService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Search for candidates
   * @param include
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiMatchingCandidatesSearch(
    include?: 'resume' | 'testSuites',
    requestBody?: {
      filters?: Array<{
        type?: 'and' | 'or';
        field:
          | 'id'
          | 'resume.tools->specializations'
          | 'resume.tools->programming_languages'
          | 'resume.tools->technologies'
          | 'resume.tools->databases'
          | 'resume.tools->relocation_cities'
          | 'resume.tools->relocation_countries'
          | 'resume.total_experience'
          | 'resume.english_language_proficiency'
          | 'resume.video'
          | 'best_test_result_score'
          | 'resume.salary_currency'
          | 'resume.min_salary'
          | 'resume.salary_period'
          | 'resume.city->id'
          | 'resume.relocation_to_diff_country'
          | 'resume.relocation_to_diff_city'
          | 'resume.about'
          | 'resume.created_at';
        operator?:
          | '<'
          | '<='
          | '>'
          | '>='
          | '='
          | '!='
          | 'like'
          | 'not like'
          | 'ilike'
          | 'not ilike'
          | 'in'
          | 'not in'
          | 'all in'
          | 'any in';
        value: string;
        nested?: Array<{
          type?: 'and' | 'or';
          field?:
            | 'id'
            | 'resume.tools->specializations'
            | 'resume.tools->programming_languages'
            | 'resume.tools->technologies'
            | 'resume.tools->databases'
            | 'resume.tools->relocation_cities'
            | 'resume.tools->relocation_countries'
            | 'resume.total_experience'
            | 'resume.english_language_proficiency'
            | 'resume.video'
            | 'best_test_result_score'
            | 'resume.salary_currency'
            | 'resume.min_salary'
            | 'resume.salary_period'
            | 'resume.city->id'
            | 'resume.relocation_to_diff_country'
            | 'resume.relocation_to_diff_city'
            | 'resume.about'
            | 'resume.created_at';
          operator?:
            | '<'
            | '<='
            | '>'
            | '>='
            | '='
            | '!='
            | 'like'
            | 'not like'
            | 'ilike'
            | 'not ilike'
            | 'in'
            | 'not in'
            | 'all in'
            | 'any in';
          value?: string;
        }>;
      }>;
      sort?: Array<{
        field: 'resume.created_at' | 'resume.total_experience' | 'best_test_result_score';
        direction?: 'asc' | 'desc';
      }>;
      search?: {
        /**
         * A search for the given value will be performed on the following fields: id
         */
        value?: string;
        /**
         * (default: true) Set it to false to perform search in case-insensitive way
         */
        case_sensitive?: boolean;
      };
    },
  ): CancelablePromise<{
    data?: Array<CandidateResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta & {
      total_unfiltered?: number;
    };
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/matching/candidates/search',
      query: {
        include: include,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Search for candidate lists
   * @param include
   * @param hasResponses
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiMatchingCandidateListsSearch(
    include?: 'users' | 'manager',
    hasResponses?: boolean,
    requestBody?: {
      filters?: Array<{
        type?: 'and' | 'or';
        field: 'name';
        operator?: '<' | '<=' | '>' | '>=' | '=' | '!=' | 'like' | 'not like' | 'ilike' | 'not ilike' | 'in' | 'not in';
        value: string;
      }>;
      search?: {
        /**
         * A search for the given value will be performed on the following fields: name
         */
        value?: string;
        /**
         * (default: true) Set it to false to perform search in case-insensitive way
         */
        case_sensitive?: boolean;
      };
    },
  ): CancelablePromise<{
    data?: Array<CandidateListResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/matching/candidate-lists/search',
      query: {
        include: include,
        has_responses: hasResponses,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Create candidate list
   * @param include
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiMatchingCandidateLists(
    include?: 'users' | 'creator',
    requestBody?: CandidateList,
  ): CancelablePromise<{
    data?: CandidateListResource;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/matching/candidate-lists',
      query: {
        include: include,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Get candidate list
   * @param candidateList Hash
   * @param include
   * @returns any OK
   * @throws ApiError
   */
  public getApiMatchingCandidateLists(
    candidateList: number,
    include?: 'users' | 'creator',
  ): CancelablePromise<{
    data?: CandidateListResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/matching/candidate-lists/{candidate_list}',
      path: {
        candidate_list: candidateList,
      },
      query: {
        include: include,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
      },
    });
  }

  /**
   * Approve candidates in a list
   * @param hash Hash
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiMatchingCandidateListsApprove(
    hash: string,
    requestBody?: {
      candidates?: Array<number>;
    },
  ): CancelablePromise<{
    data?: CandidateListResource;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/matching/candidate-lists/{hash}/approve',
      path: {
        hash: hash,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Entity not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Search for candidates in a list
   * @param candidateList Hash
   * @param include
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiMatchingCandidateListsUsersSearch(
    candidateList: number,
    include?: 'resume' | 'testSuites',
    requestBody?: {
      filters?: Array<{
        type?: 'and' | 'or';
        field?: 'id';
        operator?:
          | '<'
          | '<='
          | '>'
          | '>='
          | '='
          | '!='
          | 'like'
          | 'not like'
          | 'ilike'
          | 'not ilike'
          | 'in'
          | 'not in'
          | 'all in'
          | 'any in';
        value?: string;
        nested?: Array<{
          type?: 'and' | 'or';
          field?: 'id';
          operator?:
            | '<'
            | '<='
            | '>'
            | '>='
            | '='
            | '!='
            | 'like'
            | 'not like'
            | 'ilike'
            | 'not ilike'
            | 'in'
            | 'not in'
            | 'all in'
            | 'any in';
          value?: string;
        }>;
      }>;
      search?: {
        /**
         * A search for the given value will be performed on the following fields: id
         */
        value?: string;
        /**
         * (default: true) Set it to false to perform search in case-insensitive way
         */
        case_sensitive?: boolean;
      };
      sort?: Array<{
        field: 'resume.created_at' | 'resume.total_experience' | 'best_test_result_score';
        direction?: 'asc' | 'desc';
      }>;
    },
  ): CancelablePromise<{
    data?: Array<CandidateResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/matching/candidate-lists/{candidate_list}/users/search',
      path: {
        candidate_list: candidateList,
      },
      query: {
        include: include,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Attach users to a candidate list
   * @param candidateList Hash
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiMatchingCandidateListsUsersAttach(
    candidateList: number,
    requestBody?: {
      resources?: Array<any>;
    },
  ): CancelablePromise<{
    attached?: Array<number>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/matching/candidate-lists/{candidate_list}/users/attach',
      path: {
        candidate_list: candidateList,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Detach users from a candidate list
   * @param candidateList Hash
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public deleteApiMatchingCandidateListsUsersDetach(
    candidateList: number,
    requestBody?: {
      resources?: Array<any>;
    },
  ): CancelablePromise<{
    detached?: Array<number>;
  }> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/matching/candidate-lists/{candidate_list}/users/detach',
      path: {
        candidate_list: candidateList,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Update candidate list user pivot
   * @param candidateList Hash
   * @param user
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public patchApiMatchingCandidateListsUsersPivot(
    candidateList: string,
    user: number,
    requestBody?: {
      /**
       * Pivot fields
       */
      pivot?: {
        cost?: number | null;
        recommendation?: string | null;
      };
    },
  ): CancelablePromise<{
    updated?: Array<number>;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/matching/candidate-lists/{candidate_list}/users/{user}/pivot',
      path: {
        candidate_list: candidateList,
        user: user,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }
}
