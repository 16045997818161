/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivityFeedback } from '../models/ActivityFeedback';
import type { ActivityFeedbackResource } from '../models/ActivityFeedbackResource';
import type { ResourceLinks } from '../models/ResourceLinks';
import type { ResourceMeta } from '../models/ResourceMeta';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ActivityFeedbackService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Search for activity feedback
   * @param activity
   * @returns any OK
   * @throws ApiError
   */
  public postApiActivitiesFeedbackSearch(activity: string): CancelablePromise<{
    data?: Array<ActivityFeedbackResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/activities/{activity}/feedback/search',
      path: {
        activity: activity,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Create a batch of activity feedback
   * @param activity
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiActivitiesFeedbackBatch(
    activity: string,
    requestBody?: {
      resources?: Array<ActivityFeedback>;
    },
  ): CancelablePromise<{
    data?: Array<ActivityFeedbackResource>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/activities/{activity}/feedback/batch',
      path: {
        activity: activity,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Update a batch of activity feedback
   * @param activity
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public patchApiActivitiesFeedbackBatch(
    activity: string,
    requestBody?: {
      resources?: Array<ActivityFeedback>;
    },
  ): CancelablePromise<{
    data?: Array<ActivityFeedbackResource>;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/activities/{activity}/feedback/batch',
      path: {
        activity: activity,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Get a list of activity feedback
   * @param activity
   * @returns any OK
   * @throws ApiError
   */
  public getApiActivitiesFeedback(activity: string): CancelablePromise<{
    data?: Array<ActivityFeedbackResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/activities/{activity}/feedback',
      path: {
        activity: activity,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Create activity feedback
   * @param activity
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiActivitiesFeedback(
    activity: string,
    requestBody?: ActivityFeedback,
  ): CancelablePromise<{
    data?: ActivityFeedbackResource;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/activities/{activity}/feedback',
      path: {
        activity: activity,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Update activity feedback
   * @param activity
   * @param feedback
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public patchApiActivitiesFeedback(
    activity: string,
    feedback: number,
    requestBody?: ActivityFeedback,
  ): CancelablePromise<{
    data?: ActivityFeedbackResource;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/activities/{activity}/feedback/{feedback}',
      path: {
        activity: activity,
        feedback: feedback,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }
}
