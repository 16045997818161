/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Experience } from '../models/Experience';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ExperienceService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get a list of experience ranges
   * @returns any OK
   * @throws ApiError
   */
  public getExperience(): CancelablePromise<{
    data?: Array<Experience>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/experience',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
