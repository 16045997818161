/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AccountsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Delete user account
   * @param user
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public deleteAccountsUser(
    user: number,
    requestBody?: {
      password: string;
    },
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/accounts/{user}',
      path: {
        user: user,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Unprocessable Entity (WebDAV)`,
      },
    });
  }
}
