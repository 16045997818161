import { AvailableProvidersEnum } from '@/models/socialProviders';

export const USERNAME_MIN_LENGTH = 3;
export const USERNAME_MAX_LENGTH = 32;
export const PASSWORD_MIN_LENGTH = 8;

export const EXPERIENCE_OPTIONS = ['less_than_year', '1-2', '2-3', '3-5', '5-8', 'more_than_eight_years'];
export const SOCIAL_PROVIDERS: AvailableProvidersEnum[] = ['vkontakte', 'facebook', 'github', 'google'];
export const AVATAR_EMPTY = 'https://gravatar.com/avatar/08d3ee8a9346a2e01c24973676a492cf?s=400&d=mp&r=x';

export const TERMS_OF_USE_LINK =
  typeof window !== 'undefined' && window.location.origin.endsWith('.ru')
    ? 'https://geecko.notion.site/id-geecko-ru-4d2bd3ded782476b9a7d9a52e14c9564?pvs=4'
    : 'https://geecko.notion.site/Geecko-ID-Terms-of-Use-c6c721d02b2e447591ec8682e46ae904?pvs=4';
export const PRIVACY_POLICY_LINK =
  typeof window !== 'undefined' && window.location.origin.endsWith('.ru')
    ? 'https://geecko.notion.site/id-geecko-ru-dd6b4f37b573478c9f89754bc9b7e3ab?pvs=4'
    : 'https://geecko.notion.site/Geecko-ID-Privacy-Policy-94372d2acfb34ed8bde7b1132e5f012d?pvs=4';

export const ENV_SERVER_URL =
  typeof window !== 'undefined' && window.location.origin.endsWith('.ru')
    ? process.env.NEXT_PUBLIC_API_ROOT_RU
    : process.env.NEXT_PUBLIC_API_ROOT;

export const ENV_TELEGRAM_BOT_ID =
  typeof window !== 'undefined' && window.location.origin.endsWith('.ru')
    ? process.env.NEXT_PUBLIC_TELEGRAM_LOGIN_BOT_ID_RU
    : process.env.NEXT_PUBLIC_TELEGRAM_LOGIN_BOT_ID_COM;
