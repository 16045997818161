/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PasswordResetService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Request password reset
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postPasswordResetRequest(requestBody?: { email: string }): CancelablePromise<{
    data?: {
      status?: 'passwords.sent' | 'passwords.throttled';
    };
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/password-reset/request',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
      },
    });
  }

  /**
   * Reset password
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postPasswordResetReset(requestBody?: {
    email: string;
    token: string;
    password: string;
    password_confirmation: string;
  }): CancelablePromise<{
    data?: {
      status?: 'passwords.reset';
    };
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/password-reset/reset',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthenticated`,
      },
    });
  }
}
