/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LinkedProfileResource } from '../models/LinkedProfileResource';
import type { ResourceLinks } from '../models/ResourceLinks';
import type { ResourceMeta } from '../models/ResourceMeta';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserLinkedProfilesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get a list of linked profiles
   * @param user
   * @param withTrashed
   * @param onlyTrashed
   * @returns any OK
   * @throws ApiError
   */
  public getApiUsersLinkedProfiles(
    user: number,
    withTrashed?: boolean,
    onlyTrashed?: boolean,
  ): CancelablePromise<{
    data?: Array<LinkedProfileResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/users/{user}/linked_profiles',
      path: {
        user: user,
      },
      query: {
        with_trashed: withTrashed,
        only_trashed: onlyTrashed,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Delete linked profile
   * @param user
   * @param linkedProfile
   * @param force
   * @returns any OK
   * @throws ApiError
   */
  public deleteApiUsersLinkedProfiles(
    user: number,
    linkedProfile: number,
    force?: boolean,
  ): CancelablePromise<{
    data?: LinkedProfileResource;
  }> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/users/{user}/linked_profiles/{linked_profile}',
      path: {
        user: user,
        linked_profile: linkedProfile,
      },
      query: {
        force: force,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
      },
    });
  }
}
