import { Profile } from '@/models/profile';
import { User } from '@/models/user';

export const isProfileFilled = (user: User, profile: Profile): boolean => {
  const hasSpecializations =
    profile.specializations && Array.isArray(profile.specializations) && profile.specializations.length > 0;
  const hasSpecializationAreas =
    profile.specializationAreas && Array.isArray(profile.specializationAreas) && profile.specializationAreas.length > 0;
  const hasSelectedAreaOther =
    hasSpecializationAreas && profile.specializationAreas.length === 1 && profile.specializationAreas[0].id === 'other';

  return !(
    !user.email ||
    !user.username ||
    !user.countryCode ||
    !profile.primaryProgrammingLanguage ||
    !hasSpecializationAreas ||
    (!hasSelectedAreaOther && !hasSpecializations)
  );
};
