/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OAuthAccessGrantResource } from '../models/OAuthAccessGrantResource';
import type { ResourceLinks } from '../models/ResourceLinks';
import type { ResourceMeta } from '../models/ResourceMeta';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserOAuthAccessGrantsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Search for OAuth access grants
   * @param user
   * @param withTrashed
   * @returns any OK
   * @throws ApiError
   */
  public postApiUsersOauthAccessGrantsSearch(
    user: number,
    withTrashed?: boolean,
  ): CancelablePromise<{
    data?: Array<OAuthAccessGrantResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/users/{user}/oauth-access-grants/search',
      path: {
        user: user,
      },
      query: {
        with_trashed: withTrashed,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Revoke OAuth access grant
   * @param user
   * @param oauthAccessGrant
   * @returns any OK
   * @throws ApiError
   */
  public deleteApiUsersOauthAccessGrants(
    user: number,
    oauthAccessGrant: number,
  ): CancelablePromise<{
    data?: OAuthAccessGrantResource;
  }> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/users/{user}/oauth-access-grants/{oauth_access_grant}',
      path: {
        user: user,
        oauth_access_grant: oauthAccessGrant,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
      },
    });
  }

  /**
   * Delete revoked OAuth access grants
   * @param user
   * @returns void
   * @throws ApiError
   */
  public deleteApiUsersOauthAccessGrantsRevoked(user: number): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/users/{user}/oauth-access-grants/revoked',
      path: {
        user: user,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
      },
    });
  }
}
