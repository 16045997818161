import { FC } from 'react';

import { Spinner } from '@/components/UI/Spinner';

interface Props {
  testId?: string;
}

export const Loading: FC<Props> = ({ testId }) => (
  <div
    aria-label="Loading, please wait..."
    role="alert"
    aria-busy="true"
    data-testid={testId}
    className="geecko__loading-wrapper"
  >
    <Spinner />
  </div>
);
