/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyMarket } from '../models/CompanyMarket';
import type { Experience } from '../models/Experience';
import type { IntentionState } from '../models/IntentionState';
import type { ProgrammingLanguage } from '../models/ProgrammingLanguage';
import type { Specialization } from '../models/Specialization';
import type { SpecializationArea } from '../models/SpecializationArea';
import type { USVisaStatus } from '../models/USVisaStatus';
import type { WorkFeature } from '../models/WorkFeature';
import type { WorkingEnvironment } from '../models/WorkingEnvironment';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EnumsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get a list of programming languages
   * @returns any OK
   * @throws ApiError
   */
  public getApiEnumsProgrammingLanguages(): CancelablePromise<{
    data?: Array<ProgrammingLanguage>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/enums/programming-languages',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Get a list of work experience ranges
   * @returns any OK
   * @throws ApiError
   */
  public getApiEnumsExperience(): CancelablePromise<{
    data?: Array<Experience>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/enums/experience',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Get a list of specialization areas
   * @returns any OK
   * @throws ApiError
   */
  public getApiEnumsSpecializationAreas(): CancelablePromise<{
    data?: Array<SpecializationArea>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/enums/specialization-areas',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Get a list of specializations
   * @returns any OK
   * @throws ApiError
   */
  public getApiEnumsSpecializations(): CancelablePromise<{
    data?: Array<Specialization>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/enums/specializations',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Get a list of US visa statuses
   * @returns any OK
   * @throws ApiError
   */
  public getApiEnumsUsVisaStatuses(): CancelablePromise<{
    data?: Array<USVisaStatus>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/enums/us-visa-statuses',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Get a list of working environments
   * @returns any OK
   * @throws ApiError
   */
  public getApiEnumsWorkingEnvironments(): CancelablePromise<{
    data?: Array<WorkingEnvironment>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/enums/working-environments',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Get a list of intention states
   * @returns any OK
   * @throws ApiError
   */
  public getApiEnumsIntentionStates(): CancelablePromise<{
    data?: Array<IntentionState>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/enums/intention-states',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Get a list of company markets
   * @returns any OK
   * @throws ApiError
   */
  public getApiEnumsCompanyMarkets(): CancelablePromise<{
    data?: Array<CompanyMarket>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/enums/company-markets',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Get a list of work features
   * @returns any OK
   * @throws ApiError
   */
  public getApiEnumsWorkFeatures(): CancelablePromise<{
    data?: Array<WorkFeature>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/enums/work-features',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }
}
