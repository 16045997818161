/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ToolResource } from '../models/ToolResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ToolsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create tool
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiTools(requestBody?: {
    name: string;
    type: Array<'Service' | 'tech' | 'tech.db' | 'lang'>;
  }): CancelablePromise<{
    data?: ToolResource;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/tools',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }
}
