/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyResource } from '../models/CompanyResource';
import type { ResourceLinks } from '../models/ResourceLinks';
import type { ResourceMeta } from '../models/ResourceMeta';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CompaniesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Search for companies
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiCompaniesSearch(requestBody?: {
    filters?: Array<{
      type?: 'and' | 'or';
      field: 'name';
      operator: '<' | '<=' | '>' | '>=' | '=' | '!=' | 'like' | 'not like' | 'ilike' | 'not ilike' | 'in' | 'not in';
      value: string;
    }>;
  }): CancelablePromise<{
    data?: Array<CompanyResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/companies/search',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }
}
