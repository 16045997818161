const FALLBACKS = {
  ru: 'ru',
  // Ukrainian
  uk: 'ru',
  // Belarusian
  be: 'ru',
  // Kazakh
  kk: 'ru',
};

const AVAILABLE_LOCALES = ['en'];

export interface Locale {
  name: string;
  code: string;
  flagSign: string;
}

export const LOCALES: Locale[] = [
  {
    name: 'Русский',
    code: 'ru',
    flagSign: `🇷🇺`,
  },
  {
    name: 'English',
    code: 'en',
    flagSign: `🇺🇸`,
  },
];

export const getLocaleFromString = (localeFromNavigator: string): string => {
  let locale = localeFromNavigator;
  if (localeFromNavigator.includes('-')) {
    [locale] = localeFromNavigator.split('-');
  }
  if (AVAILABLE_LOCALES.includes(locale)) {
    return locale;
  }
  if (locale in FALLBACKS && AVAILABLE_LOCALES.includes(FALLBACKS[locale])) {
    return FALLBACKS[locale];
  }

  return 'en';
};

export const getLocaleByCode = (code: string): Locale => LOCALES.find((item) => item.code === code) || LOCALES[0];
