import { Button } from '@geeckocom/core-ui';
import { RefreshIcon } from '@heroicons/react/outline';
import { useTranslations } from 'next-intl';
import { FC, ReactNode } from 'react';

interface Props {
  title?: ReactNode;
  description?: ReactNode;
  onRetry?: () => void;
}

export const PageError: FC<Props> = ({ title, description, onRetry }) => {
  const t = useTranslations();

  return (
    <div className="h-screen flex items-center justify-center">
      <div className="flex flex-col space-y-4">
        {title && <h1 className="font-bold text-2xl text-text-primary">{title}</h1>}
        {description && <p className="text-text-secondary">{description}</p>}
        {onRetry && (
          <div>
            <Button leadingIcon={<RefreshIcon className="w-4 h-4" />} onClick={onRetry}>
              {t('tryAgain')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
