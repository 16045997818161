import '../src/styles/app.scss';
import '@geeckocom/core-ui/dist/styles/bundle.css';
import '../src/styles/tailwind.css';
import 'react-phone-number-input/style.css';
import 'focus-visible';
import 'dayjs/locale/en';

import { ToastContainer } from '@geeckocom/core-ui';
import dayjs from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import DayJsDuration from 'dayjs/plugin/duration';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import DayJsRelativeTime from 'dayjs/plugin/relativeTime';
import DayJsTimezone from 'dayjs/plugin/timezone';
import DayJsUtc from 'dayjs/plugin/utc';
import Head from 'next/head';
import Script from 'next/script';
import { Suspense, useRef } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Hydrate } from 'react-query/hydration';

import { AuthGuard } from '@/entities/authorization';
import { App } from '@/layouts/App';
import { Loading } from '@/layouts/Loading';
import { GeeckoAppProps } from '@/shared/types';
import { reduxWrapper } from '@/store';
import { Common } from '@/widgets/common';

dayjs.extend(LocalizedFormat);
dayjs.extend(DayJsUtc);
dayjs.extend(DayJsTimezone);
dayjs.extend(CustomParseFormat);
dayjs.extend(DayJsDuration);
dayjs.extend(DayJsRelativeTime);

function GeeckoApp({ Component, pageProps, err }: GeeckoAppProps) {
  const queryClientRef = useRef<QueryClient>();

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
          refetchOnWindowFocus: false,
        },
      },
    });
  }

  return (
    <>
      <Head>
        <title>Geecko ID</title>
      </Head>
      <Script
        id="color-theme"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
      window.disableColorSchemeChange = true;
      // Insert this script in your index.html right after the <body> tag.
      // This will help to prevent a flash if dark mode is the default.
      (function () {
        // Change these if you use something different in your hook.
        const THEME_DATA_ATTRIBUTE = 'data-geecko-theme'
        const DARK_VALUE = 'dark';
        const LIGHT_VALUE = 'light';
        var storageKey = 'darkMode';

        function setClassOnDocumentBody(darkMode) {
          document.body.setAttribute(THEME_DATA_ATTRIBUTE, darkMode ? DARK_VALUE : LIGHT_VALUE)
        }

        var preferDarkQuery = '(prefers-color-scheme: dark)';
        var mql = window.matchMedia(preferDarkQuery);
        var supportsColorSchemeQuery = mql.media === preferDarkQuery;
        var localStorageTheme = null;
        try {
          if (window.location.search.indexOf('dark_mode=on') >= 0) {
            localStorage.setItem(storageKey, 'true');
            window.disableColorSchemeChange = true;
          }
          try {
            localStorageTheme = localStorage.getItem(storageKey);
          } catch (e) {
            //
          }
        } catch (err) {}
        var localStorageExists = localStorageTheme !== null;
        if (localStorageExists) {
          localStorageTheme = JSON.parse(localStorageTheme);
        }

        // Determine the source of truth
        if (localStorageExists) {
          // source of truth from localStorage
          setClassOnDocumentBody(localStorageTheme);
        } else if (supportsColorSchemeQuery) {
          // source of truth from system
          setClassOnDocumentBody(mql.matches);
          try {
            localStorage.setItem(storageKey, mql.matches);
          } catch (e) {
            //
          }
        } else {
          // source of truth from document.body
          var isDarkMode = document.body.getAttribute(THEME_DATA_ATTRIBUTE) === DARK_VALUE;
          try {
            localStorage.setItem(storageKey, JSON.stringify(isDarkMode));
          } catch (e) {
            //
          }
        }
      })();
  `,
        }}
      />
      <QueryClientProvider client={queryClientRef.current}>
        <Hydrate state={pageProps?.dehydratedState}>
          <Suspense fallback={<Loading />}>
            <App>
              <AuthGuard>
                <Common>
                  <Component {...pageProps} err={err} />
                </Common>
              </AuthGuard>
            </App>
          </Suspense>
        </Hydrate>
        <ToastContainer />
        {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </>
  );
}

export default reduxWrapper.withRedux(GeeckoApp);
