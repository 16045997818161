/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResourceLinks } from '../models/ResourceLinks';
import type { ResourceMeta } from '../models/ResourceMeta';
import type { TestResult } from '../models/TestResult';
import type { TestResultResource } from '../models/TestResultResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserTestResultsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get a list of test results
   * @param user
   * @returns any OK
   * @throws ApiError
   */
  public getApiUsersTestResults(user: number): CancelablePromise<{
    data?: Array<TestResultResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/users/{user}/test_results',
      path: {
        user: user,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Create test result
   * @param user
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiUsersTestResults(
    user: number,
    requestBody?: TestResult,
  ): CancelablePromise<{
    data?: TestResultResource;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/users/{user}/test_results',
      path: {
        user: user,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Get test result
   * @param user
   * @param testResult
   * @param include
   * @returns any OK
   * @throws ApiError
   */
  public getApiUsersTestResults1(
    user: number,
    testResult: number,
    include?: 'testSuite',
  ): CancelablePromise<{
    data?: TestResultResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/users/{user}/test_results/{test_result}',
      path: {
        user: user,
        test_result: testResult,
      },
      query: {
        include: include,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
      },
    });
  }
}
