import { ActivityAuthProvider } from '@/shared/api';

import { Company, decodeCompany } from './company';

export type CounterTypeEnum = 'none' | 'logo' | 'value' | 'registrations';

interface ActivityBanner {
  id: number;
  placement: string;
  mode: string;
  desktop?: string;
  desktopBackdropEnabled?: boolean;
  desktopBackdropColor?: string | null;
  desktopBackdropOpacity?: number | null;
  mobile?: string;
  mobileBackdropEnabled?: boolean;
  mobileBackdropColor?: string | null;
  mobileBackdropOpacity?: number | null;
}

export const decodeActivityBanner = (json: any): ActivityBanner => ({
  id: json.id,
  placement: json.placement,
  mode: json.mode,
  desktop: json.desktop_image_url,
  desktopBackdropEnabled: json.desktop_image_backdrop_enabled,
  desktopBackdropColor: json.desktop_image_backdrop_color,
  desktopBackdropOpacity: json.desktop_image_backdrop_opacity,
  mobile: json.mobile_image_url,
  mobileBackdropEnabled: json.mobile_image_backdrop_enabled,
  mobileBackdropColor: json.mobile_image_backdrop_color,
  mobileBackdropOpacity: json.mobile_image_backdrop_opacity,
});

export interface Activity {
  id: number;
  slug: string;
  name: string;
  url: string;
  locales: string[];
  areas: string[];
  specializations: string[];
  banners: ActivityBanner[];
  logoUrl?: string;
  authProviders: ActivityAuthProvider[];
  requirePhone: boolean;
  phoneVerificationIsSkippable: boolean;
  phoneVerificationNoticeTitle?: string;
  phoneVerificationNoticeDescription?: string;
  featuredProgrammingLanguages: string[];
  intentionsCollection: boolean;
  intentionsText: string | null;
  company?: Company;
  intentionsTextFormatted: string;
  onboardingTitleFormatted: string;
  onboardingDescriptionFormatted: string;
  onboardingCounterType: CounterTypeEnum;
  onboardingCounterCaption?: any;
  onboardingCounterCaptionFormatted?: string | null;
  onboardingCounterValue?: string | null;
  counterLogoUrl?: string;
  legalDocuments: {
    privacyPolicyUrl: string;
    termsOfServiceUrl: string;
  };
  email_authentication_disabled: boolean;
}

export const decodeActivity = (json: any): Activity => ({
  id: json.id,
  slug: json.slug,
  name: json.name,
  url: json.url,
  locales: json.locales,
  areas: json.specializations_areas,
  specializations: json.specializations,
  banners: json.banners && Array.isArray(json.banners) ? json.banners.map(decodeActivityBanner) : [],
  logoUrl: json.logo_url,
  authProviders: Array.isArray(json.auth_providers) ? json.auth_providers : [],
  requirePhone: !!json.require_phone,
  phoneVerificationIsSkippable: !!json.phone_verification_is_skippable,
  phoneVerificationNoticeTitle: json.phone_verification_notice_title_formatted,
  phoneVerificationNoticeDescription: json.phone_verification_notice_description_formatted,
  featuredProgrammingLanguages:
    json?.featured_programming_languages && Array.isArray(json?.featured_programming_languages)
      ? json?.featured_programming_languages
      : [],
  company: json.company ? decodeCompany(json.company) : undefined,
  intentionsCollection: !!json.intentions_collection,
  intentionsText: json.intentions_text_formatted,
  intentionsTextFormatted: json.intentions_text_formatted,
  onboardingTitleFormatted: json.onboarding_title_formatted,
  onboardingDescriptionFormatted: json.onboarding_description_formatted,
  onboardingCounterType: json.onboarding_counter_type,
  onboardingCounterCaption: json.onboarding_counter_caption,
  onboardingCounterCaptionFormatted: json.onboarding_counter_caption_formatted,
  onboardingCounterValue: json.onboarding_counter_value,
  counterLogoUrl: json.counter_logo_url,
  legalDocuments: {
    privacyPolicyUrl: json?.legal_documents?.privacy_policy_url,
    termsOfServiceUrl: json?.legal_documents?.terms_of_service_url,
  },
  email_authentication_disabled: json.email_authentication_disabled,
});
