/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { DictionariesService } from './services/DictionariesService';
import { EnumsService } from './services/EnumsService';
import { SystemService } from './services/SystemService';
import { ToolsService } from './services/ToolsService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class UtilityApiClient {
  public readonly dictionaries: DictionariesService;
  public readonly enums: EnumsService;
  public readonly system: SystemService;
  public readonly tools: ToolsService;

  public readonly request: BaseHttpRequest;

  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? 'https://utility-api.geecko.test/api',
      VERSION: config?.VERSION ?? '1.0.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.dictionaries = new DictionariesService(this.request);
    this.enums = new EnumsService(this.request);
    this.system = new SystemService(this.request);
    this.tools = new ToolsService(this.request);
  }
}
