/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TestSuiteResource } from '../models/TestSuiteResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TestSuitesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Start a test session
   * @param testSuite
   * @returns any OK
   * @throws ApiError
   */
  public postApiTestSuitesSession(testSuite: number): CancelablePromise<{
    data?: {
      test_result_id: number;
      test_session_url: string;
    };
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/test-suites/{test_suite}/session',
      path: {
        test_suite: testSuite,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Get test suite
   * @param testSuite
   * @returns any OK
   * @throws ApiError
   */
  public getApiTestSuites(testSuite: number): CancelablePromise<{
    data?: TestSuiteResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/test-suites/{test_suite}',
      path: {
        test_suite: testSuite,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
      },
    });
  }
}
