import { AxiosError, AxiosResponse } from 'axios';

type AxiosErrorWithResponse<T = unknown, D = unknown> = AxiosError<T, D> & { response: AxiosResponse<T, D> };

export const hasErrorsKey = (value: unknown): value is { errors: unknown } =>
  typeof value === 'object' && value !== null && 'errors' in value;

export const hasMessageKey = (value: unknown): value is { message: unknown } =>
  typeof value === 'object' && value !== null && 'message' in value;

export const hasErrorKey = (value: unknown): value is { error: unknown } =>
  typeof value === 'object' && value !== null && 'error' in value;

type ErrorsBag = Record<string, string[]>;

/**
 * Checks if the specified axios error is an 422 Laravel Validation Error and also aims as type guard.
 */
export const isApiValidationError = (error: AxiosError): error is AxiosErrorWithResponse<{ errors: ErrorsBag }> => {
  if (!error.response || error.response.status !== 422) {
    return false;
  }

  if (!hasErrorsKey(error.response.data)) {
    return false;
  }

  if (typeof error.response.data.errors !== 'object' || error.response.data.errors === null) {
    return false;
  }

  return Object.values(error.response.data.errors).every((value) => Array.isArray(value));
};

export const isApiErrorWithMessage = (error: AxiosError): error is AxiosErrorWithResponse<{ message: string }> => {
  if (!error.response) {
    return false;
  }

  if (!hasMessageKey(error.response.data)) {
    return false;
  }

  return typeof error.response.data.message === 'string';
};

export const isApiErrorWithErrorMessage = (error: AxiosError): error is AxiosErrorWithResponse<{ error: string }> => {
  if (!error.response) {
    return false;
  }

  if (!hasErrorKey(error.response.data)) {
    return false;
  }

  return typeof error.response.data.error === 'string';
};
