/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Profile } from '../models/Profile';
import type { ProfileResource } from '../models/ProfileResource';
import type { ResourceLinks } from '../models/ResourceLinks';
import type { ResourceMeta } from '../models/ResourceMeta';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProfilesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get profile by hash
   * @param hash
   * @returns any OK
   * @throws ApiError
   */
  public getProfilesLookupHash(hash: string): CancelablePromise<{
    data?: ProfileResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/profiles/lookup/{hash}',
      path: {
        hash: hash,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get a list of profiles
   * @returns any OK
   * @throws ApiError
   */
  public getApiProfiles(): CancelablePromise<{
    data?: Array<ProfileResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/profiles',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Create profile
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiProfiles(requestBody?: Profile): CancelablePromise<{
    data?: ProfileResource;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/profiles',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Get profile
   * @param profile
   * @returns any OK
   * @throws ApiError
   */
  public getApiProfiles1(profile: number): CancelablePromise<{
    data?: ProfileResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/profiles/{profile}',
      path: {
        profile: profile,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
      },
    });
  }

  /**
   * Update profile
   * @param profile
   * @param finalize
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public patchApiProfiles(
    profile: number,
    finalize?: boolean,
    requestBody?: Profile,
  ): CancelablePromise<{
    data?: ProfileResource;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/profiles/{profile}',
      path: {
        profile: profile,
      },
      query: {
        finalize: finalize,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Delete profile
   * @param profile
   * @returns any OK
   * @throws ApiError
   */
  public deleteApiProfiles(profile: number): CancelablePromise<{
    data?: ProfileResource;
  }> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/profiles/{profile}',
      path: {
        profile: profile,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
      },
    });
  }

  /**
   * Get current profile
   * @param include The list of relations to include in the response
   * @returns any OK
   * @throws ApiError
   */
  public getCurrentUserProfile(include?: 'profile'): CancelablePromise<{
    data?: ProfileResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/profiles/current',
      query: {
        include: include,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }
}
