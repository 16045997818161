import { configureStore, Store } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';

import { authModule, AuthState } from '@/entities/authorization';
import { SalaryPeriodEnum } from '@/shared/api';
import { settingsModule, SettingsState } from '@/store/modules/settings';

export type AppState = {
  [settingsModule.name]?: SettingsState;
  [authModule.name]?: AuthState;
};

export const makeStore = (preloadedState?: AppState): Store<AppState> =>
  configureStore({
    reducer: {
      [settingsModule.name]: settingsModule.reducer,
      [authModule.name]: authModule.reducer,
    },
    preloadedState: preloadedState || {},
    devTools: true,
  });

export type AppStore = ReturnType<typeof makeStore>;

const wrapperMakeStore = () => makeStore();

export const reduxWrapper = createWrapper<AppStore>(wrapperMakeStore);

// TODO: Move selectors to modules
export const selectLocale = (state: AppState): string => state?.[settingsModule.name]?.locale || 'ru';
export const selectCurrentActivityCode = (state: AppState): string | undefined =>
  state?.[authModule.name]?.activityCode;
export const selectProgrammingLanguageForActivity = (state: AppState): string =>
  state?.[settingsModule.name]?.programmingLanguageForActivity || '';
export const selectSalaryPeriod = (state: AppState): SalaryPeriodEnum =>
  state?.[settingsModule.name]?.salaryPeriod || 'monthly';
export const selectCurrentSection = (state: AppState): string => state?.[authModule.name]?.currentSection || '';
