import { useTranslations } from 'next-intl';
import { useEffect, useRef, useState } from 'react';

import { FCWithChildren } from '@/shared/types';
import { ENV_SERVER_URL, ENV_TELEGRAM_BOT_ID } from '@/utils/constants';
import { showErrorToast } from '@/utils/showToast';

interface Props {
  lang?: string;
  className?: string;
  dataAuthUrl?: string;
  redirectPath?: string;
}

export const TelegramLoginButton: FCWithChildren<Props> = ({
  lang = 'en',
  dataAuthUrl = `${ENV_SERVER_URL}${process.env.NEXT_PUBLIC_TELEGRAM_LOGIN_AUTH_URL}`,
  className,
  children,
  redirectPath,
}) => {
  const t = useTranslations();
  const telegramLoginButtonNew = useRef<HTMLButtonElement>(null);
  const [isLoaded, setLoaded] = useState(false);
  const handleAuth = (event) => {
    let data;
    try {
      data = JSON.parse(event.data);
    } catch (e) {
      data = {};
    }

    switch (data.event) {
      case 'auth_user':
        if (dataAuthUrl) {
          let authUrl = dataAuthUrl;
          authUrl += authUrl.indexOf('?') >= 0 ? '&' : '?';
          const params: string[] = [];
          Object.entries(data.auth_data).forEach(([key, value]) => {
            params.push(`${key}=${encodeURIComponent(value as string | number | boolean)}`);
          });
          authUrl += params.join('&');

          if (redirectPath) {
            authUrl += `?redirect_path=${encodeURIComponent(redirectPath)}`;
          }

          window.location.href = authUrl;
        }
        break;
      case 'unauthorized':
        showErrorToast(t('features.telegram_login_button.telegram_unauthorized_error_notification'));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const widgetScript = document.createElement('script');
    widgetScript.src = '/scripts/widget-frame.js';
    widgetScript.async = true;
    if (telegramLoginButtonNew.current) {
      telegramLoginButtonNew.current.appendChild(widgetScript);
    }
    widgetScript.onload = () => {
      const initScript = document.createElement('script');
      initScript.text = `
      TWidgetLogin.init(
        "widget_login", ${ENV_TELEGRAM_BOT_ID}, {"origin":"${window.location.origin}","embed":1,"request_access":"write","lang":"${lang}"}, false, "${lang}"
      );`;
      if (telegramLoginButtonNew.current) {
        telegramLoginButtonNew.current.appendChild(initScript);
      }
      window.addEventListener('message', handleAuth, false);
      setLoaded(true);
    };
    return () => {
      window.removeEventListener('message', handleAuth);
    };
  }, []);

  return (
    <button
      disabled={!isLoaded}
      className={className}
      onClick={() => (window as any).TWidgetLogin.auth()}
      ref={telegramLoginButtonNew}
      // id="widget_login"
    >
      {children}
    </button>
  );
};
