/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResourceLinks } from '../models/ResourceLinks';
import type { ResourceMeta } from '../models/ResourceMeta';
import type { User } from '../models/User';
import type { UserResource } from '../models/UserResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Search for users
   * @param withTrashed
   * @param onlyTrashed
   * @param include
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiUsersSearch(
    withTrashed?: boolean,
    onlyTrashed?: boolean,
    include?: 'profile',
    requestBody?: {
      filters?: Array<{
        type?: 'and' | 'or';
        field: 'id';
        operator: '<' | '<=' | '>' | '>=' | '=' | '!=' | 'like' | 'not like' | 'ilike' | 'not ilike' | 'in' | 'not in';
        value: string;
      }>;
    },
  ): CancelablePromise<{
    data?: Array<UserResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/users/search',
      query: {
        with_trashed: withTrashed,
        only_trashed: onlyTrashed,
        include: include,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Get a list of users
   * @param withTrashed
   * @param onlyTrashed
   * @param include
   * @returns any OK
   * @throws ApiError
   */
  public getApiUsers(
    withTrashed?: boolean,
    onlyTrashed?: boolean,
    include?: 'profile',
  ): CancelablePromise<{
    data?: Array<UserResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/users',
      query: {
        with_trashed: withTrashed,
        only_trashed: onlyTrashed,
        include: include,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Create user
   * @param include
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiUsers(
    include?: 'profile',
    requestBody?: User,
  ): CancelablePromise<{
    data?: UserResource;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/users',
      query: {
        include: include,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Get user
   * @param user
   * @param withTrashed
   * @param onlyTrashed
   * @param include
   * @returns any OK
   * @throws ApiError
   */
  public getApiUsers1(
    user: number,
    withTrashed?: boolean,
    onlyTrashed?: boolean,
    include?: 'profile',
  ): CancelablePromise<{
    data?: UserResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/users/{user}',
      path: {
        user: user,
      },
      query: {
        with_trashed: withTrashed,
        only_trashed: onlyTrashed,
        include: include,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
      },
    });
  }

  /**
   * Update user
   * @param user
   * @param include
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public patchApiUsers(
    user: number,
    include?: 'profile',
    requestBody?: User,
  ): CancelablePromise<{
    data?: UserResource;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/users/{user}',
      path: {
        user: user,
      },
      query: {
        include: include,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Delete user
   * @param user
   * @param force
   * @param include
   * @returns any OK
   * @throws ApiError
   */
  public deleteApiUsers(
    user: number,
    force?: boolean,
    include?: 'profile',
  ): CancelablePromise<{
    data?: UserResource;
  }> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/users/{user}',
      path: {
        user: user,
      },
      query: {
        force: force,
        include: include,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
      },
    });
  }

  /**
   * Get current user
   * @returns any OK
   * @throws ApiError
   */
  public getCurrentUser(): CancelablePromise<{
    data?: UserResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/users/current',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Update password
   * @param user
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public postUsersIdUpdatePassword(
    user: number,
    requestBody?: {
      old_password?: string;
      password: string;
      password_confirmation: string;
    },
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/users/{user}/update-password',
      path: {
        user: user,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Forbidden`,
        422: `Unprocessable Entity (WebDAV)`,
      },
    });
  }

  /**
   * Update avatar
   * @param user
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postUsersUserAvatar(
    user: number,
    requestBody?: {
      avatar: Blob;
    },
  ): CancelablePromise<{
    data?: UserResource;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/users/{user}/avatar',
      path: {
        user: user,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Remove avatar
   * @param user
   * @returns any OK
   * @throws ApiError
   */
  public deleteUsersUserAvatar(user: number): CancelablePromise<{
    data?: UserResource;
  }> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/users/{user}/avatar',
      path: {
        user: user,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get user by hash
   * @param hash
   * @returns any OK
   * @throws ApiError
   */
  public getUsersLookupHash(hash: string): CancelablePromise<{
    data?: UserResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/users/lookup/{hash}',
      path: {
        hash: hash,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Update phone
   * @param user
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postUsersUserUpdatePhone(
    user: string,
    requestBody?: {
      phone: string;
    },
  ): CancelablePromise<{
    data?: UserResource;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/users/{user}/update-phone',
      path: {
        user: user,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Verify phone
   * @param user
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postUsersUserVerifyPhone(
    user: string,
    requestBody?: {
      code: string;
    },
  ): CancelablePromise<{
    data?: UserResource;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/users/{user}/verify-phone',
      path: {
        user: user,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Unprocessable Entity (WebDAV)`,
      },
    });
  }

  /**
   * Resend phone verification code
   * @param user
   * @returns any OK
   * @throws ApiError
   */
  public postUsersUserResendVerificationCode(user: string): CancelablePromise<{
    data?: {
      resent?: boolean;
    };
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/users/{user}/resend-verification-code',
      path: {
        user: user,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}
