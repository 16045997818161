import { decodeGenericValue, GenericValue } from '@/models/generic-value';

export interface Profile {
  id: number;
  userId: number;
  primaryProgrammingLanguage?: GenericValue;
  experience?: GenericValue;
  specializations: GenericValue[];
  specializationAreas: GenericValue[];
  rating: number;
  latestCompany?: string;
}

export const decodeProfile = (json: any): Profile => ({
  id: json.id,
  userId: json.user_id,
  primaryProgrammingLanguage: json.primary_programming_language
    ? decodeGenericValue(json.primary_programming_language)
    : undefined,
  experience: json.experience ? decodeGenericValue(json.experience) : undefined,
  specializations: (json.specializations && Array.isArray(json.specializations) ? json.specializations : []).map(
    decodeGenericValue,
  ),
  specializationAreas: (json.specialization_areas && Array.isArray(json.specialization_areas)
    ? json.specialization_areas
    : []
  ).map(decodeGenericValue),
  rating: json.rating,
  latestCompany: json.latest_company,
});
