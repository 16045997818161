import { useRouter } from 'next/router';
import { ReactNode, useEffect, useRef } from 'react';

import { authModel } from '@/entities/authorization';
import { Loading } from '@/layouts/Loading';
import { FCWithChildren } from '@/shared/types';

interface Props {
  loader?: ReactNode;
}

export const Authorized: FCWithChildren<Props> = ({ loader, children }) => {
  const { asPath, isReady, replace } = useRouter();
  const wasRedirectedRef = useRef(false);

  const authorizedStatus = authModel.useIsAuthorized();

  useEffect(() => {
    if (authorizedStatus === 'authorized') {
      wasRedirectedRef.current = false;
    }
  }, [authorizedStatus]);

  useEffect(() => {
    if (authorizedStatus !== 'authorized' && !wasRedirectedRef.current) {
      wasRedirectedRef.current = true;
      replace(`/signup?return_url=${encodeURIComponent(asPath)}`);
    }
  }, [asPath, isReady, authorizedStatus]);

  if (authorizedStatus === 'authorized') {
    return <>{children}</>;
  }

  return loader ? <>{loader}</> : <Loading />;
};
