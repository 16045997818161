import axios from 'axios';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { useSelector } from 'react-redux';

import { authModuleSelectors } from '@/entities/authorization';
import { useApi } from '@/hooks/useApi';
import { decodeProfile, Profile } from '@/models/profile';

export const CURRENT_PROFILE_QUERY_KEY = ['currentProfile'];

type UseCurrentProfileOptions = {
  enabled?: boolean;
  tag?: string;
};

export const useCurrentProfile = (options: UseCurrentProfileOptions = {}): UseQueryResult<Profile> => {
  const { enabled } = options;
  const api = useApi();

  const impersonationToken = useSelector(authModuleSelectors.impersonationToken);
  const queryKey = useMemo(
    () => (impersonationToken ? ['profile-for-token', impersonationToken] : CURRENT_PROFILE_QUERY_KEY),
    [impersonationToken],
  );

  return useQuery(
    queryKey,
    async () => {
      try {
        const response = await api.get('/api/profiles/current');
        return decodeProfile(response.data.data);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          if (e?.response?.status === 401) {
            return undefined;
          }
        }

        throw e;
      }
    },
    { enabled },
  );
};
