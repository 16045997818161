import { setCookie } from 'nookies';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectLocale } from '@/store';
import { settingsModule } from '@/store/modules/settings';

interface UseLocaleResult {
  locale: string;
  changeLocale: (newLocale: string) => void;
}

export const LOCALE_COOKIE_NAME = 'geecko_id_lang';

export const useLocale = (): UseLocaleResult => {
  const locale = useSelector(selectLocale);
  const dispatch = useDispatch();

  const changeLocale = useCallback(
    (newLocale: string): void => {
      setCookie(null, LOCALE_COOKIE_NAME, newLocale, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      });

      dispatch(settingsModule.actions.setLocale({ locale: newLocale }));
    },
    [dispatch],
  );

  return { locale, changeLocale };
};
