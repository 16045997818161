/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResourceLinks } from '../models/ResourceLinks';
import type { ResourceMeta } from '../models/ResourceMeta';
import type { Segment } from '../models/Segment';
import type { SegmentResource } from '../models/SegmentResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserSegmentsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Search for segments
   * @param user
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiUsersSegmentsSearch(
    user: number,
    requestBody?: {
      filters?: Array<{
        type?: 'and' | 'or';
        field: 'slug' | 'name';
        operator: '<' | '<=' | '>' | '>=' | '=' | '!=' | 'like' | 'not like' | 'ilike' | 'not ilike' | 'in' | 'not in';
        value: string;
      }>;
    },
  ): CancelablePromise<{
    data?: Array<SegmentResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/users/{user}/segments/search',
      path: {
        user: user,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Sync segments
   * @param user
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public patchApiUsersSegmentsSync(
    user: number,
    requestBody?: {
      resources?: Array<any>;
    },
  ): CancelablePromise<{
    attached?: Array<number>;
    detached?: Array<number>;
    updated?: Array<number>;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/users/{user}/segments/sync',
      path: {
        user: user,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Toggle segments
   * @param user
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public patchApiUsersSegmentsToggle(
    user: number,
    requestBody?: {
      resources?: Array<any>;
    },
  ): CancelablePromise<{
    attached?: Array<number>;
    detached?: Array<number>;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/users/{user}/segments/toggle',
      path: {
        user: user,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Update pivot
   * @param user
   * @param segment
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public patchApiUsersSegmentsPivot(
    user: number,
    segment: number,
    requestBody?: {
      /**
       * Pivot fields
       */
      pivot?: any;
    },
  ): CancelablePromise<{
    updated?: Array<number>;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/users/{user}/segments/{segment}/pivot',
      path: {
        user: user,
        segment: segment,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Attach segments
   * @param user
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiUsersSegmentsAttach(
    user: number,
    requestBody?: {
      resources?: Array<any>;
    },
  ): CancelablePromise<{
    attached?: Array<number>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/users/{user}/segments/attach',
      path: {
        user: user,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Detach segments
   * @param user
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public deleteApiUsersSegmentsDetach(
    user: number,
    requestBody?: {
      resources?: Array<any>;
    },
  ): CancelablePromise<{
    detached?: Array<number>;
  }> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/users/{user}/segments/detach',
      path: {
        user: user,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Get a list of segments
   * @param user
   * @returns any OK
   * @throws ApiError
   */
  public getApiUsersSegments(user: number): CancelablePromise<{
    data?: Array<SegmentResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/users/{user}/segments',
      path: {
        user: user,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Create segment
   * @param user
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiUsersSegments(
    user: number,
    requestBody?: Segment,
  ): CancelablePromise<{
    data?: SegmentResource;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/users/{user}/segments',
      path: {
        user: user,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Delete segment
   * @param user
   * @param segment
   * @returns any OK
   * @throws ApiError
   */
  public deleteApiUsersSegments(
    user: number,
    segment: number,
  ): CancelablePromise<{
    data?: SegmentResource;
  }> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/users/{user}/segments/{segment}',
      path: {
        user: user,
        segment: segment,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
      },
    });
  }

  /**
   * Assign segments
   * @param user
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postUserSegmentsAssign(
    user: number,
    requestBody?: {
      activity_slug?: string;
      segments?: Array<{
        slug?: string;
        name?: string;
      }>;
    },
  ): CancelablePromise<{
    created?: Array<number>;
    assigned?: Array<number>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/users/{user}/segments/assign',
      path: {
        user: user,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Unprocessable Entity (WebDAV)`,
        500: `Internal Server Error`,
      },
    });
  }
}
