/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResourceLinks } from '../models/ResourceLinks';
import type { ResourceMeta } from '../models/ResourceMeta';
import type { Wallet } from '../models/Wallet';
import type { WalletResource } from '../models/WalletResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserWalletsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create wallet
   * @param user
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiUsersWallets(
    user: number,
    requestBody?: Wallet & {
      message?: string;
      signature?: string;
    },
  ): CancelablePromise<{
    data?: WalletResource;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/users/{user}/wallets',
      path: {
        user: user,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Search for wallets
   * @param user
   * @returns any OK
   * @throws ApiError
   */
  public postApiUsersWalletsSearch(user: number): CancelablePromise<{
    data?: Array<WalletResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/users/{user}/wallets/search',
      path: {
        user: user,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Delete wallet
   * @param user
   * @param wallet
   * @returns any OK
   * @throws ApiError
   */
  public deleteApiUsersWallets(
    user: number,
    wallet: number,
  ): CancelablePromise<{
    data?: WalletResource;
  }> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/users/{user}/wallets/{wallet}',
      path: {
        user: user,
        wallet: wallet,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
      },
    });
  }
}
