import { FC } from 'react';
import { SpinnerCircularFixed } from 'spinners-react';

interface Props {
  size?: number;
  color?: string;
  secondaryColor?: string;
  speed?: number;
}

export const Spinner: FC<Props> = ({ size = 56, color = '#1F44CC', secondaryColor = '#DADFE8', speed = 200 }) => (
  <SpinnerCircularFixed size={size} color={color} secondaryColor={secondaryColor} speed={speed} />
);
