import * as Sentry from '@sentry/nextjs';

import { User } from '@/models/user';
import { useCurrentUser } from '@/queries/auth/useCurrentUser';

const userToSentryUser: (user: User) => Sentry.User = (user) => ({
  id: user.id.toString(),
  username: user.username,
  hash: user.hash,
  first_name: user.firstName,
  last_name: user.lastName,
});

export const useEnrichSentryWithUser = () => {
  useCurrentUser({
    onSuccess: (user, isImpersonation) => {
      if (user && user.id) {
        Sentry.configureScope((scope) => {
          scope.setTag('auth_type', isImpersonation ? 'impersonation' : 'standard');
          scope.setUser(userToSentryUser(user));
        });
      }
    },
  });
};
