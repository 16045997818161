/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class VisitsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Track visit
   * @param geeckoTrackingId Cookie containing tracking token
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public postVisits(
    geeckoTrackingId?: string,
    requestBody?: {
      landing_url: string;
      referrer_url?: string;
      /**
       * Google Click Identifier
       */
      gclid?: string;
      utm_source?: string;
      utm_campaign?: string;
      utm_medium?: string;
      utm_term?: string;
      utm_content?: string;
      ref?: string;
    },
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/visits',
      cookies: {
        geecko_tracking_id: geeckoTrackingId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        422: `Unprocessable Entity (WebDAV)`,
      },
    });
  }
}
