/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OtpService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Request one-time password
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public postOtp(requestBody?: { email?: string }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/otp',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Authenticate via one-time password
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postOtpVerify(requestBody?: { password?: string }): CancelablePromise<{
    data?: {
      /**
       * Authentication link that can be used to automatically login a user
       */
      auth_url?: string;
      /**
       * Indicates whether user has just been registered
       */
      registered?: boolean;
      user_id?: number;
      user_hash?: string;
      access_token?: string;
    };
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/otp/verify',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }
}
