import { useEffect } from 'react';

import { authModel } from '@/entities/authorization';
import { Loading } from '@/layouts/Loading';
import { FCWithChildren } from '@/shared/types';
// import * as Sentry from '@sentry/nextjs';

export const PublicGuard: FCWithChildren = ({ children }) => {
  const authStatus = authModel.useIsAuthorized();
  const redirectAfterLogin = authModel.useRedirectAfterAuth();
  // Sentry.captureMessage(`hello from PublicGuard, authStatus: ${authStatus}`);
  useEffect(() => {
    // Sentry.captureMessage(`hello from PublicGuard useEffect, authStatus: ${authStatus}`);
    if (authStatus === 'authorized' || authStatus === 'not_finished') {
      redirectAfterLogin();
    }
  }, [authStatus]);

  if (authStatus === 'authorized' || authStatus === 'not_finished') {
    return <Loading />;
  }
  return <>{children}</>;
};
