import { showToast } from '@geeckocom/core-ui';
import { ReactNode } from 'react';

export const showSuccessToast = (content: ReactNode): void => {
  showToast(content, { type: 'success' });
};

export const showErrorToast = (content: ReactNode): void => {
  showToast(content, { type: 'error' });
};
