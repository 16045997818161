import { Button } from '@geeckocom/core-ui';
import { useTranslations } from 'next-intl';
import { FC } from 'react';

interface Props {
  resetErrorBoundary: () => void;
}

export const ErrorBoundaryComponent: FC<Props> = ({ resetErrorBoundary }) => {
  const t = useTranslations();
  return (
    <div className="error-boundary-layout">
      <div className="error-boundary-layout__inner">
        <div>{t('errorOnFetchMessage')}</div>
        <Button type="button" onClick={() => resetErrorBoundary()}>
          {t('tryAgain')}
        </Button>
      </div>
    </div>
  );
};
