/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AchievementResource } from '../models/AchievementResource';
import type { ResourceLinks } from '../models/ResourceLinks';
import type { ResourceMeta } from '../models/ResourceMeta';
import type { UserAchievementPivot } from '../models/UserAchievementPivot';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserAchievementsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Update pivot
   * @param user
   * @param achievement
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public patchApiUsersAchievementsPivot(
    user: number,
    achievement: number,
    requestBody?: {
      /**
       * Pivot fields
       */
      pivot?: {
        visible?: boolean;
      };
    },
  ): CancelablePromise<{
    updated?: Array<number>;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/users/{user}/achievements/{achievement}/pivot',
      path: {
        user: user,
        achievement: achievement,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Attach achievements
   * @param user
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiUsersAchievementsAttach(
    user: number,
    requestBody?: {
      resources?: Array<any>;
    },
  ): CancelablePromise<{
    attached?: Array<number>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/users/{user}/achievements/attach',
      path: {
        user: user,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Get a list of achievements
   * @param user
   * @param withHidden
   * @returns any OK
   * @throws ApiError
   */
  public getApiUsersAchievements(
    user: number,
    withHidden?: boolean,
  ): CancelablePromise<{
    data?: Array<AchievementResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/users/{user}/achievements',
      path: {
        user: user,
      },
      query: {
        with_hidden: withHidden,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Get achievements by user hash
   * @param hash
   * @returns any OK
   * @throws ApiError
   */
  public getUsersUserAchievementsByUserHash(hash: string): CancelablePromise<{
    data?: Array<
      AchievementResource & {
        pivot?: UserAchievementPivot;
      }
    >;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/users/lookup/{hash}/achievements',
      path: {
        hash: hash,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
