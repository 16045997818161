/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Sign In
   * Authenticate user using email and password
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public postLogin(requestBody?: { email: string; password: string }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/login',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Invalid credentials`,
        422: `The given data was invalid`,
      },
    });
  }

  /**
   * Register
   * Register a new user using email and password
   * @param requestBody
   * @returns string Registration successful
   * @throws ApiError
   */
  public postRegister(requestBody?: {
    email: string;
    password: string;
    timezone?: string | null;
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/register',
      body: requestBody,
      mediaType: 'application/json',
      responseHeader: 'Set-Cookie',
      errors: {
        422: `The given data was invalid`,
      },
    });
  }

  /**
   * Logout
   * @returns void
   * @throws ApiError
   */
  public postLogout(): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/logout',
      errors: {
        401: `Unauthenticated`,
      },
    });
  }

  /**
   * Check email availability
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postValidateEmail(requestBody?: { email?: string }): CancelablePromise<{
    available?: boolean;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/check-email-availability',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
      },
    });
  }
}
