/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LocationService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get user location by IP address
   * @returns any OK
   * @throws ApiError
   */
  public getLocation(): CancelablePromise<{
    data?: {
      /**
       * A lower-cased two letter country code in ISO 639-1 (Alpha 2) format
       */
      country_code?: string | null;
      region?: string | null;
      city?: string | null;
    };
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/location',
      errors: {
        400: `Bad Request`,
      },
    });
  }
}
