import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { useSelector } from 'react-redux';

import { authModuleSelectors } from '@/entities/authorization';
import { useApi } from '@/hooks/useApi';
import { Activity, decodeActivity } from '@/models/activity';

export const useActivity = (slug?: string): UseQueryResult<Activity> => {
  const api = useApi();
  const slugInStore = useSelector(authModuleSelectors.activityCode);
  const actualSlug = slug || slugInStore;
  return useQuery(
    ['activity', actualSlug],
    async () => {
      const response = await api.get(`/api/activity-context/${actualSlug}`);
      return decodeActivity(response.data.data);
    },
    {
      retry: false,
      retryOnMount: false,
      enabled: !!actualSlug,
    },
  );
};
