interface DataScope {
  id: string;
  name: string;
}

export interface Company {
  id: number;
  name: string;
  slug: string;
  personalDataScopes: DataScope[];
  privacyPolicyUrl?: string;
}

export const decodeCompany = (json: any): Company => ({
  id: json.id,
  name: json.name,
  slug: json.slug,
  personalDataScopes: json.personal_data_scopes,
  privacyPolicyUrl: json.privacy_policy_url,
});
