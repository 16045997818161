/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AchievementsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Upload achievement icon
   * @param activity
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postActivitiesIdAchievementsIcon(
    activity: number,
    requestBody?: {
      icon?: Blob;
    },
  ): CancelablePromise<{
    data?: {
      upload_id?: string;
    };
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/activities/{activity}/achievements/icon',
      path: {
        activity: activity,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
