import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { getLocale } from '@/modules/locale/getLocale';
import { SalaryPeriodEnum } from '@/shared/api';

const hydrate = createAction(HYDRATE);

export interface SettingsState {
  locale: string;
  programmingLanguageForActivity?: string;
  salaryPeriod: SalaryPeriodEnum;
}

export const settingsModule = createSlice({
  name: 'settings',

  initialState: { locale: getLocale(), salaryPeriod: 'monthly' } as SettingsState,

  reducers: {
    setLocale(state, action: PayloadAction<{ locale: string }>) {
      state.locale = action.payload.locale;
    },
    setProgrammingLanguageForActivity(state, action: PayloadAction<string | undefined>) {
      state.programmingLanguageForActivity = action.payload;
    },
    setSalaryPeriod(state, action: PayloadAction<{ salaryPeriod: SalaryPeriodEnum }>) {
      state.salaryPeriod = action.payload.salaryPeriod;
    },
  },

  extraReducers(builder) {
    builder.addCase(hydrate, (state, action) => ({
      ...state,
      ...(action.payload as any)[settingsModule.name],
    }));
  },
});
