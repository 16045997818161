/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResourceLinks } from '../models/ResourceLinks';
import type { ResourceMeta } from '../models/ResourceMeta';
import type { Stage } from '../models/Stage';
import type { StageResource } from '../models/StageResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserStagesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Search for stages
   * @param user
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiUsersStagesSearch(
    user: number,
    requestBody?: {
      filters?: Array<{
        type?: 'and' | 'or';
        field: 'slug' | 'name';
        operator: '<' | '<=' | '>' | '>=' | '=' | '!=' | 'like' | 'not like' | 'ilike' | 'not ilike' | 'in' | 'not in';
        value: string;
      }>;
    },
  ): CancelablePromise<{
    data?: Array<StageResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/users/{user}/stages/search',
      path: {
        user: user,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Sync stages
   * @param user
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public patchApiUsersStagesSync(
    user: number,
    requestBody?: {
      resources?: Array<any>;
    },
  ): CancelablePromise<{
    attached?: Array<number>;
    detached?: Array<number>;
    updated?: Array<number>;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/users/{user}/stages/sync',
      path: {
        user: user,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Toggle stages
   * @param user
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public patchApiUsersStagesToggle(
    user: number,
    requestBody?: {
      resources?: Array<any>;
    },
  ): CancelablePromise<{
    attached?: Array<number>;
    detached?: Array<number>;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/users/{user}/stages/toggle',
      path: {
        user: user,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Update pivot
   * @param user
   * @param stage
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public patchApiUsersStagesPivot(
    user: number,
    stage: number,
    requestBody?: {
      /**
       * Pivot fields
       */
      pivot?: any;
    },
  ): CancelablePromise<{
    updated?: Array<number>;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/users/{user}/stages/{stage}/pivot',
      path: {
        user: user,
        stage: stage,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Attach stages
   * @param user
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiUsersStagesAttach(
    user: number,
    requestBody?: {
      resources?: Array<any>;
    },
  ): CancelablePromise<{
    attached?: Array<number>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/users/{user}/stages/attach',
      path: {
        user: user,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Detach stages
   * @param user
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public deleteApiUsersStagesDetach(
    user: number,
    requestBody?: {
      resources?: Array<any>;
    },
  ): CancelablePromise<{
    detached?: Array<number>;
  }> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/users/{user}/stages/detach',
      path: {
        user: user,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Get a list of stages
   * @param user
   * @returns any OK
   * @throws ApiError
   */
  public getApiUsersStages(user: number): CancelablePromise<{
    data?: Array<StageResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/users/{user}/stages',
      path: {
        user: user,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Create stage
   * @param user
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiUsersStages(
    user: number,
    requestBody?: Stage,
  ): CancelablePromise<{
    data?: StageResource;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/users/{user}/stages',
      path: {
        user: user,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Delete stage
   * @param user
   * @param stage
   * @returns any OK
   * @throws ApiError
   */
  public deleteApiUsersStages(
    user: number,
    stage: number,
  ): CancelablePromise<{
    data?: StageResource;
  }> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/users/{user}/stages/{stage}',
      path: {
        user: user,
        stage: stage,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
      },
    });
  }

  /**
   * Assign stages
   * @param user
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postUserStagesAssign(
    user: number,
    requestBody?: {
      activity_slug?: string;
      stages?: Array<{
        slug?: string;
        name?: string;
        position?: number;
      }>;
    },
  ): CancelablePromise<{
    created?: Array<number>;
    assigned?: Array<number>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/users/{user}/stages/assign',
      path: {
        user: user,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Unprocessable Entity (WebDAV)`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Rollback stage
   * @param user
   * @param stage
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public deleteApiUsersStagesRollback(
    user: number,
    stage: string,
    requestBody?: {
      activity_slug?: string;
    },
  ): CancelablePromise<{
    data?: {
      detached?: boolean;
    };
  }> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/users/{user}/stages/{stage}/rollback',
      path: {
        user: user,
        stage: stage,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Unprocessable Entity (WebDAV)`,
        500: `Internal Server Error`,
      },
    });
  }
}
