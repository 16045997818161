/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResourceLinks } from '../models/ResourceLinks';
import type { ResourceMeta } from '../models/ResourceMeta';
import type { TestSuiteCategoryResource } from '../models/TestSuiteCategoryResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TestSuiteCategoriesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get a list of test suite categories
   * @param include
   * @returns any OK
   * @throws ApiError
   */
  public getApiTestSuiteCategories(include?: 'testSuites'): CancelablePromise<{
    data?: Array<TestSuiteCategoryResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/test-suite-categories',
      query: {
        include: include,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }
}
