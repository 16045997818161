import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';

import { authModule, authModuleSelectors, AuthStatus } from '@/entities/authorization';
import { isProfileFilled } from '@/entities/authorization/model/is-profile-filled';
import { useActivity } from '@/queries/auth/useActivity';
import { useCurrentProfile } from '@/queries/auth/useCurrentProfile';
import { useCurrentUser } from '@/queries/auth/useCurrentUser';
import { queryParamToString } from '@/utils/queryParamToString';
import { showErrorToast } from '@/utils/showToast';

type UseAuthResult = {
  isLoading: boolean;
  isError: boolean;
  retry: () => void;
};

export const useOauthSignupErrors = (): void => {
  const t = useTranslations();
  const { query } = useRouter();

  useEffect(() => {
    const { result } = query;
    switch (result) {
      case 'err_user_exists':
        showErrorToast(t('entities.authorization.oauth_errors.err_user_exists'));
        break;
      case 'err_profile_exists':
        showErrorToast(t('entities.authorization.oauth_errors.err_profile_exists'));
        break;
      case 'err_user_details':
        showErrorToast(t('entities.authorization.oauth_errors.err_user_details'));
        break;
      case 'err_user_create':
        showErrorToast(t('entities.authorization.oauth_errors.err_user_create'));
        break;
      default:
      //
    }
  }, [query.result]);
};

export const useAuth = (): UseAuthResult => {
  const router = useRouter();

  const dispatch = useDispatch();
  const isTransition = useSelector(authModuleSelectors.isTransition);
  const impersonationToken = useSelector(authModuleSelectors.impersonationToken);

  useOauthSignupErrors();

  const canLoad = useMemo(() => {
    if (!router.isReady) {
      return false;
    }

    // If impersonation token is presented in query params but still isn't in Redux, wait for it.
    if (typeof router.query.token === 'string' && router.query.context === 'impersonation') {
      return !!impersonationToken;
    }
    return true;
  }, [router.isReady, router.query.token, router.query.context, impersonationToken]);

  useEffect(() => {
    if (typeof router.query.token === 'string' && router.query.context === 'impersonation') {
      dispatch(authModule.actions.setImpersonationToken(router.query.token));
    }
  }, [dispatch, router.query.token, router.query.context]);

  useMount(() => {
    try {
      if (!router.query.activity) {
        const activityFromSession = sessionStorage.getItem('activity_context');
        if (activityFromSession) {
          dispatch(authModule.actions.setActivityCode(queryParamToString(activityFromSession)));
        }
      }
    } catch {
      //
    }
  });

  useEffect(() => {
    if (typeof router.query.activity === 'string' && router.query.activity) {
      dispatch(authModule.actions.setActivityCode(queryParamToString(router.query.activity)));

      try {
        sessionStorage.setItem('activity_context', router.query.activity);
      } catch {
        //
      }
    }
  }, [router.query.activity]);

  useEffect(() => {
    if (typeof router.query.return_url === 'string' && !!router.query.return_url) {
      dispatch(authModule.actions.setReturnUrl(router.query.return_url));
    }
    if (typeof router.query.redirect_url === 'string' && !!router.query.redirect_url) {
      dispatch(authModule.actions.setRedirectUrl(router.query.redirect_url));
    }
  }, [router.query.return_url, router.query.redirect_url]);

  const userData = useCurrentUser({ enabled: canLoad, tag: 'useAuth' });
  const profileData = useCurrentProfile({ enabled: canLoad, tag: 'useAuth' });
  const activity = useActivity();

  /**
   * State of profile: -1 - unauthorized, 0 - not finished, 1 - finished
   */
  const profileReadyState = useMemo(() => {
    if (!userData.data || !profileData.data || userData.isLoading || profileData.isLoading) {
      return -1;
    }

    return isProfileFilled(userData.data, profileData.data) ? 1 : 0;
  }, [userData.data, profileData.data]);

  const redirectUrl = useMemo(() => {
    if (profileReadyState === 0 && router.pathname !== '/signup-profile') {
      return '/signup-profile';
    }

    return null;
  }, [profileReadyState, router.pathname]);

  const isLoading = !canLoad || userData.isLoading || profileData.isLoading || activity.isLoading || !!redirectUrl;

  useEffect(() => {
    if (redirectUrl) {
      const queryParams = isTransition ? '' : `?return_url=${encodeURIComponent(router.asPath)}`;
      router.replace(`${redirectUrl}${queryParams}`);
    }
  }, [redirectUrl, isTransition]);

  const isError = userData.isError || profileData.isError || activity.isError;

  const retry = useCallback((): Promise<unknown> | void => {
    const refetches: Array<Promise<unknown>> = [];
    if (userData.isError) {
      refetches.push(userData.refetch());
    }
    if (profileData.isError) {
      refetches.push(profileData.refetch());
    }
    if (activity.isError) {
      refetches.push(activity.refetch());
    }

    return Promise.all(refetches);
  }, [
    userData.isError,
    userData.refetch,
    profileData.isError,
    profileData.refetch,
    activity.isError,
    activity.refetch,
  ]);

  return {
    isLoading,
    isError,
    retry,
  };
};

const DEFAULT_URL_AFTER_AUTH = '/';

export const useIsAuthorized = (): AuthStatus => {
  const userData = useCurrentUser();
  const profileData = useCurrentProfile();

  return useMemo(() => {
    if (userData.isLoading || profileData.isLoading) {
      return 'loading';
    }

    if (userData.isError || profileData.isError) {
      return 'error';
    }

    if (!userData.data || !profileData.data) {
      return 'unauthorized';
    }

    if (!isProfileFilled(userData.data, profileData.data)) {
      return 'not_finished';
    }

    return 'authorized';
  }, [userData.isFetched, profileData.isFetched, userData.data, profileData.data]);
};

type UseRedirectAfterAuthResult = () => Promise<boolean>;

export const useRedirectAfterAuth = (): UseRedirectAfterAuthResult => {
  const router = useRouter();
  const returnUrl = useSelector(authModuleSelectors.returnUrl);
  const redirectUrl = useSelector(authModuleSelectors.redirectUrl);

  const calculatedReturnUrl = useMemo(() => {
    if (typeof returnUrl !== 'string') {
      return DEFAULT_URL_AFTER_AUTH;
    }
    return returnUrl.startsWith('/') && !returnUrl.startsWith('//') ? returnUrl : DEFAULT_URL_AFTER_AUTH;
  }, [returnUrl]);

  const calculatedRedirectUrl = useMemo(() => {
    if (typeof redirectUrl !== 'string') {
      return DEFAULT_URL_AFTER_AUTH;
    }
    return redirectUrl;
  }, [redirectUrl]);

  return useCallback(
    () => (redirectUrl ? router.replace(calculatedRedirectUrl) : router.replace(calculatedReturnUrl)),
    [calculatedReturnUrl, calculatedRedirectUrl],
  );
};

export const useResetIsTransition = (): void => {
  const { isReady } = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isReady) {
      dispatch(authModule.actions.setIsTransition(false));
    }
  }, [isReady]);
};

export const useResetReturnUrl = (): void => {
  const { isReady, asPath } = useRouter();
  const returnUrl = useSelector(authModuleSelectors.returnUrl);
  const dispatch = useDispatch();

  useEffect(() => {
    if (returnUrl && isReady && asPath && asPath === returnUrl) {
      dispatch(authModule.actions.setReturnUrl(undefined));
    }
  }, [isReady, returnUrl, asPath, dispatch]);
};
