import { createAction, createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

const hydrate = createAction(HYDRATE);

export interface AuthState {
  activityCode?: string;
  currentSection?: string;
  /**
   * Impersonation token can be set via query params and is used to authenticate for content managers
   */
  impersonationToken?: string;
  /**
   * Url to redirect after finishing signup/signin process
   */
  returnUrl?: string;

  /**
   * Unix timestamp when user finished signup on /signup-profile page
   */
  signupFinishedAt?: number;

  /**
   * Whether page is in transition
   */
  isTransition?: boolean;
  redirectUrl?: string;
}

export const authModule = createSlice({
  name: 'auth',

  initialState: {} as AuthState,

  reducers: {
    setActivityCode(state, action: PayloadAction<string | undefined>) {
      state.activityCode = action.payload;
    },
    setCurrentSection(state, action: PayloadAction<string | undefined>) {
      state.currentSection = action.payload;
    },
    setImpersonationToken(state, action: PayloadAction<string | undefined>) {
      state.impersonationToken = action.payload;
    },
    setReturnUrl(state, action: PayloadAction<string | undefined>) {
      state.returnUrl = action.payload;
    },
    setRedirectUrl(state, action: PayloadAction<string | undefined>) {
      state.redirectUrl = action.payload;
    },
    setFinishedSignupAt(state, action: PayloadAction<number | undefined>) {
      state.signupFinishedAt = action.payload;
    },
    setIsTransition(state, action: PayloadAction<boolean | undefined>) {
      state.isTransition = action.payload;
    },
  },

  extraReducers(builder) {
    builder.addCase(hydrate, (state, action) => ({
      ...state,
      ...(action.payload as any)[authModule.name],
    }));
  },
});

const selectState = (state: { [authModule.name]?: AuthState }) => state;
const selectAuthState = createDraftSafeSelector(selectState, (state) => state[authModule.name] || {});

export const authModuleSelectors = {
  impersonationToken: createDraftSafeSelector(selectAuthState, (authState) => authState.impersonationToken),
  activityCode: createDraftSafeSelector(selectAuthState, (authState) => authState.activityCode),
  returnUrl: createDraftSafeSelector(selectAuthState, (authState) => authState.returnUrl),
  redirectUrl: createDraftSafeSelector(selectAuthState, (authState) => authState.redirectUrl),
  signupFinishedAt: createDraftSafeSelector(selectAuthState, (authState) => authState.signupFinishedAt),
  isTransition: createDraftSafeSelector(selectAuthState, (authState) => authState.isTransition),
};
