import { Profile } from '@/models/profile';

export interface User {
  id: number;
  email: string;
  username: string;
  hasPassword?: boolean;
  firstName: string;
  lastName?: string;
  phone: string | null;
  telegram: string | null;
  countryCode?: string;
  country?: string;
  region: string | null;
  locale?: string;
  city: string | null;
  hash: string | null;
  photoUrl: string | null;
  publicProfileUrl: string;
  phoneVerifiedAt?: string;
  skippedPhoneVerification: boolean;
}

export const decodeUser = (json: any): User => ({
  id: json?.id,
  email: json?.email,
  username: json?.username,
  hasPassword: json?.password_set,
  firstName: json?.first_name,
  lastName: json?.last_name || null,
  phone: json?.phone,
  telegram: json?.telegram,
  countryCode: json?.country_code,
  country: json?.country,
  region: json?.region,
  city: json?.city,
  locale: json.locale,
  hash: json.hash,
  photoUrl: json?.photo_url,
  publicProfileUrl: json?.public_profile_url,
  phoneVerifiedAt: json?.phone_verified_at,
  skippedPhoneVerification: !!json?.skipped_phone_verification,
});

/**
 * @deprecated
 */
export const isProfileFilled = (user: User, profile: Profile): boolean => {
  const hasSpecializations =
    profile.specializations && Array.isArray(profile.specializations) && profile.specializations.length > 0;
  const hasSpecializationAreas =
    profile.specializationAreas && Array.isArray(profile.specializationAreas) && profile.specializationAreas.length > 0;
  const hasSelectedAreaOther =
    hasSpecializationAreas && profile.specializationAreas.length === 1 && profile.specializationAreas[0].id === 'other';

  return !(
    !user.email ||
    !user.username ||
    !user.countryCode ||
    !profile.primaryProgrammingLanguage ||
    !hasSpecializationAreas ||
    (!hasSelectedAreaOther && !hasSpecializations)
  );
};
