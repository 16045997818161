import { NextIntlProvider } from 'next-intl';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';

import { authModel } from '@/entities/authorization';
import { useLocale } from '@/hooks/useLocale';
import enStrings from '@/lang/en.json';
import ruStrings from '@/lang/ru.json';
import { ErrorBoundaryComponent } from '@/shared/error-boundary-component';
import { FCWithChildren } from '@/shared/types';

export const App: FCWithChildren = ({ children }) => {
  const { locale, changeLocale } = useLocale();
  authModel.useResetIsTransition();
  authModel.useResetReturnUrl();

  useEffect(() => {
    if (window.location.origin.endsWith('.ru')) {
      changeLocale('ru');
    } else {
      changeLocale('en');
    }
  });

  const getMessageFallback = ({ namespace, key }) => {
    const path = [namespace, key].filter((part) => part != null).join('.');
    return path.split('.').reduce((object, key) => object[key], ruStrings);
  };

  return (
    <NextIntlProvider
      locale={locale}
      messages={locale === 'ru' ? ruStrings : enStrings}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getMessageFallback={getMessageFallback}
      defaultTranslationValues={{
        bold: (children) => <strong>{children}</strong>,
        italic: (children) => <i>{children}</i>,
      }}
    >
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            onReset={reset}
            fallbackRender={({ resetErrorBoundary }) => (
              <ErrorBoundaryComponent resetErrorBoundary={resetErrorBoundary} />
            )}
          >
            {children}
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    </NextIntlProvider>
  );
};
