/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Intention } from '../models/Intention';
import type { IntentionResource } from '../models/IntentionResource';
import type { ResourceLinks } from '../models/ResourceLinks';
import type { ResourceMeta } from '../models/ResourceMeta';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class IntentionsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get a list of intentions
   * @returns any OK
   * @throws ApiError
   */
  public getApiIntentions(): CancelablePromise<{
    data?: Array<IntentionResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/intentions',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Create intention
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiIntentions(
    requestBody?: Intention & {
      activity_slug?: string;
      contact?: string | null;
      resume_url?: string | null;
    },
  ): CancelablePromise<{
    data?: IntentionResource;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/intentions',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Search for intentions
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiIntentionsSearch(requestBody?: {
    filters?: Array<{
      type?: 'and' | 'or';
      field: 'activity.slug';
      operator: '<' | '<=' | '>' | '>=' | '=' | '!=' | 'like' | 'not like' | 'ilike' | 'not ilike' | 'in' | 'not in';
      value: string;
    }>;
  }): CancelablePromise<{
    data?: Array<IntentionResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/intentions/search',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Get activity-intention state
   * @param activity
   * @returns any OK
   * @throws ApiError
   */
  public getApiIntentionsState(activity: string): CancelablePromise<{
    collected?: boolean;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/intentions/state/{activity}',
      path: {
        activity: activity,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Update intention
   * @param intention
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public patchApiIntentions(
    intention: number,
    requestBody?: Intention,
  ): CancelablePromise<{
    data?: IntentionResource;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/intentions/{intention}',
      path: {
        intention: intention,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }
}
