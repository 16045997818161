/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Notification } from '../models/Notification';
import type { NotificationResource } from '../models/NotificationResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NotificationsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create notification
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiNotifications(requestBody?: Notification): CancelablePromise<{
    data?: NotificationResource;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/notifications',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Get notification
   * @param notification
   * @returns any OK
   * @throws ApiError
   */
  public getApiNotifications(notification: number): CancelablePromise<{
    data?: NotificationResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/notifications/{notification}',
      path: {
        notification: notification,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
      },
    });
  }

  /**
   * Upload attachment
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postNotificationsAttachments(requestBody?: { attachment?: Blob }): CancelablePromise<{
    data?: {
      upload_id?: string;
    };
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/notifications/attachments',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Unprocessable Entity (WebDAV)`,
        500: `Internal Server Error`,
      },
    });
  }
}
