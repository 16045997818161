import {
  useIsAuthorized,
  useRedirectAfterAuth,
  useResetIsTransition,
  useResetReturnUrl,
} from '@/entities/authorization/model';

export { AuthGuard } from './components/auth-guard';
export { Authorized } from './components/authorized';
export { PublicGuard } from './components/public-guard';
export { TelegramLoginButton } from './components/telegram-login-button';
export { authModule, authModuleSelectors } from './model/store';
export type { AuthState } from './model/store';
export type { AuthStatus } from './types';
export const authModel = { useRedirectAfterAuth, useIsAuthorized, useResetIsTransition, useResetReturnUrl };
