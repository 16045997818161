/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Achievement } from '../models/Achievement';
import type { AchievementResource } from '../models/AchievementResource';
import type { ResourceLinks } from '../models/ResourceLinks';
import type { ResourceMeta } from '../models/ResourceMeta';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ActivityAchievementsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Search for achievements
   * @param activity
   * @returns any OK
   * @throws ApiError
   */
  public postApiActivitiesAchievementsSearch(activity: number): CancelablePromise<{
    data?: Array<AchievementResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/activities/{activity}/achievements/search',
      path: {
        activity: activity,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Associate achievement with activity
   * @param activity
   * @returns any OK
   * @throws ApiError
   */
  public postApiActivitiesAchievementsAssociate(activity: number): CancelablePromise<{
    data?: AchievementResource;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/activities/{activity}/achievements/associate',
      path: {
        activity: activity,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Dissociate achievement from activity
   * @param activity
   * @param achievement
   * @returns any OK
   * @throws ApiError
   */
  public deleteApiActivitiesAchievementsDissociate(
    activity: number,
    achievement: number,
  ): CancelablePromise<{
    data?: AchievementResource;
  }> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/activities/{activity}/achievements/{achievement}/dissociate',
      path: {
        activity: activity,
        achievement: achievement,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Get a list of achievements
   * @param activity
   * @returns any OK
   * @throws ApiError
   */
  public getApiActivitiesAchievements(activity: number): CancelablePromise<{
    data?: Array<AchievementResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/activities/{activity}/achievements',
      path: {
        activity: activity,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Create achievement
   * @param activity
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiActivitiesAchievements(
    activity: number,
    requestBody?: Achievement,
  ): CancelablePromise<{
    data?: AchievementResource;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/activities/{activity}/achievements',
      path: {
        activity: activity,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Delete achievement
   * @param activity
   * @param achievement
   * @returns any OK
   * @throws ApiError
   */
  public deleteApiActivitiesAchievements(
    activity: number,
    achievement: number,
  ): CancelablePromise<{
    data?: AchievementResource;
  }> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/activities/{activity}/achievements/{achievement}',
      path: {
        activity: activity,
        achievement: achievement,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
      },
    });
  }
}
