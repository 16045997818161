/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SpecializationArea } from '../models/SpecializationArea';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SpecializationsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get a list of specializations
   * @returns any A list of specializations grouped by specialization area
   * @throws ApiError
   */
  public getSpecializations(): CancelablePromise<{
    data?: Array<SpecializationArea>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/specializations',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }
}
