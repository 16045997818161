import { useTranslations } from 'next-intl';

import { PageError } from '@/components/PageError';
import { useAuth } from '@/entities/authorization/model';
import { Loading } from '@/layouts/Loading';
import { FCWithChildren } from '@/shared/types';

export const AuthGuard: FCWithChildren = ({ children }) => {
  const t = useTranslations();
  const { isLoading, isError, retry } = useAuth();

  if (isLoading) {
    return <Loading testId="auth-guard" />;
  }

  if (isError) {
    return (
      <PageError
        title={t('entities.authorization.auth_guard.error.title')}
        description={t('entities.authorization.auth_guard.error.message')}
        onRetry={retry}
      />
    );
  }

  return <>{children}</>;
};
