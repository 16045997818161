import { parseCookies } from 'nookies';

import { LOCALE_COOKIE_NAME } from '@/modules/locale/constants';
import { getLocaleFromString } from '@/utils/localeUtils';

export const getLocale = (): string => {
  const cookies = parseCookies();
  if (cookies[LOCALE_COOKIE_NAME]) {
    return getLocaleFromString(cookies[LOCALE_COOKIE_NAME]);
  }

  return getLocaleFromString(typeof navigator !== 'undefined' ? navigator.language : '');
};
