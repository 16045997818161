/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JobResource } from '../models/JobResource';
import type { ResourceLinks } from '../models/ResourceLinks';
import type { ResourceMeta } from '../models/ResourceMeta';
import type { SpecializationAreaEnum } from '../models/SpecializationAreaEnum';
import type { WorkingEnvironmentEnum } from '../models/WorkingEnvironmentEnum';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class JobsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Search for jobs
   * @param recommended
   * @param page
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiJobsSearch(
    recommended?: boolean,
    page?: number,
    requestBody?: {
      filters?: Array<{
        type?: 'and' | 'or';
        field?: 'specialization_area' | 'technologies' | 'city';
        operator?:
          | '<'
          | '<='
          | '>'
          | '>='
          | '='
          | '!='
          | 'like'
          | 'not like'
          | 'ilike'
          | 'not ilike'
          | 'in'
          | 'not in'
          | 'all in'
          | 'any in';
        value?: string;
        nested?: Array<{
          type?: 'and' | 'or';
          field?: 'specialization_area' | 'technologies' | 'city';
          operator?:
            | '<'
            | '<='
            | '>'
            | '>='
            | '='
            | '!='
            | 'like'
            | 'not like'
            | 'ilike'
            | 'not ilike'
            | 'in'
            | 'not in'
            | 'all in'
            | 'any in';
          value?: string;
        }>;
      }>;
    },
  ): CancelablePromise<{
    data?: Array<JobResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta & {
      filters?: {
        specialization_area?: {
          values?: Array<SpecializationAreaEnum>;
        };
        working_environment?: {
          values?: Array<WorkingEnvironmentEnum>;
        };
        technologies?: {
          values?: Array<string>;
        };
        city?: {
          values?: string;
        };
      };
    };
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/jobs/search',
      query: {
        recommended: recommended,
        page: page,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Get job
   * @param job
   * @returns any OK
   * @throws ApiError
   */
  public getApiJobs(job: number): CancelablePromise<{
    data?: JobResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/jobs/{job}',
      path: {
        job: job,
      },
      errors: {
        400: `You have already responded to the given job.`,
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
      },
    });
  }

  /**
   * Respond to a job
   * @param job
   * @returns void
   * @throws ApiError
   */
  public postApiJobsResponse(job: number): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/jobs/{job}/response',
      path: {
        job: job,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
      },
    });
  }
}
