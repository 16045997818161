/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AccountsService } from './services/AccountsService';
import { AchievementsService } from './services/AchievementsService';
import { ActivitiesService } from './services/ActivitiesService';
import { ActivityAchievementsService } from './services/ActivityAchievementsService';
import { ActivityFeedbackService } from './services/ActivityFeedbackService';
import { ActivitySegmentsService } from './services/ActivitySegmentsService';
import { ActivityStagesService } from './services/ActivityStagesService';
import { ActivityVisitsService } from './services/ActivityVisitsService';
import { AuthService } from './services/AuthService';
import { CompaniesService } from './services/CompaniesService';
import { ConsentsService } from './services/ConsentsService';
import { CountriesService } from './services/CountriesService';
import { ExperienceService } from './services/ExperienceService';
import { IntentionsService } from './services/IntentionsService';
import { JobsService } from './services/JobsService';
import { LocationService } from './services/LocationService';
import { MatchingService } from './services/MatchingService';
import { NotificationsService } from './services/NotificationsService';
import { OtpService } from './services/OtpService';
import { PasswordResetService } from './services/PasswordResetService';
import { ProfilesService } from './services/ProfilesService';
import { ProgrammingLanguagesService } from './services/ProgrammingLanguagesService';
import { RedirectsService } from './services/RedirectsService';
import { ResumeService } from './services/ResumeService';
import { ResumeTestResultsService } from './services/ResumeTestResultsService';
import { SpecializationsService } from './services/SpecializationsService';
import { SystemService } from './services/SystemService';
import { TestSuiteCategoriesService } from './services/TestSuiteCategoriesService';
import { TestSuitesService } from './services/TestSuitesService';
import { UserAchievementsService } from './services/UserAchievementsService';
import { UserLinkedProfilesService } from './services/UserLinkedProfilesService';
import { UserOAuthAccessGrantsService } from './services/UserOAuthAccessGrantsService';
import { UsersService } from './services/UsersService';
import { UserSegmentsService } from './services/UserSegmentsService';
import { UserStagesService } from './services/UserStagesService';
import { UserTestResultsService } from './services/UserTestResultsService';
import { UserWalletsService } from './services/UserWalletsService';
import { VisitsService } from './services/VisitsService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AppClient {
  public readonly accounts: AccountsService;
  public readonly achievements: AchievementsService;
  public readonly activities: ActivitiesService;
  public readonly activityAchievements: ActivityAchievementsService;
  public readonly activityFeedback: ActivityFeedbackService;
  public readonly activitySegments: ActivitySegmentsService;
  public readonly activityStages: ActivityStagesService;
  public readonly activityVisits: ActivityVisitsService;
  public readonly auth: AuthService;
  public readonly companies: CompaniesService;
  public readonly consents: ConsentsService;
  public readonly countries: CountriesService;
  public readonly experience: ExperienceService;
  public readonly intentions: IntentionsService;
  public readonly jobs: JobsService;
  public readonly location: LocationService;
  public readonly matching: MatchingService;
  public readonly notifications: NotificationsService;
  public readonly otp: OtpService;
  public readonly passwordReset: PasswordResetService;
  public readonly profiles: ProfilesService;
  public readonly programmingLanguages: ProgrammingLanguagesService;
  public readonly redirects: RedirectsService;
  public readonly resume: ResumeService;
  public readonly resumeTestResults: ResumeTestResultsService;
  public readonly specializations: SpecializationsService;
  public readonly system: SystemService;
  public readonly testSuiteCategories: TestSuiteCategoriesService;
  public readonly testSuites: TestSuitesService;
  public readonly userAchievements: UserAchievementsService;
  public readonly userLinkedProfiles: UserLinkedProfilesService;
  public readonly userOAuthAccessGrants: UserOAuthAccessGrantsService;
  public readonly users: UsersService;
  public readonly userSegments: UserSegmentsService;
  public readonly userStages: UserStagesService;
  public readonly userTestResults: UserTestResultsService;
  public readonly userWallets: UserWalletsService;
  public readonly visits: VisitsService;

  public readonly request: BaseHttpRequest;

  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? 'http://id-api.geecko.test',
      VERSION: config?.VERSION ?? '1.0.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.accounts = new AccountsService(this.request);
    this.achievements = new AchievementsService(this.request);
    this.activities = new ActivitiesService(this.request);
    this.activityAchievements = new ActivityAchievementsService(this.request);
    this.activityFeedback = new ActivityFeedbackService(this.request);
    this.activitySegments = new ActivitySegmentsService(this.request);
    this.activityStages = new ActivityStagesService(this.request);
    this.activityVisits = new ActivityVisitsService(this.request);
    this.auth = new AuthService(this.request);
    this.companies = new CompaniesService(this.request);
    this.consents = new ConsentsService(this.request);
    this.countries = new CountriesService(this.request);
    this.experience = new ExperienceService(this.request);
    this.intentions = new IntentionsService(this.request);
    this.jobs = new JobsService(this.request);
    this.location = new LocationService(this.request);
    this.matching = new MatchingService(this.request);
    this.notifications = new NotificationsService(this.request);
    this.otp = new OtpService(this.request);
    this.passwordReset = new PasswordResetService(this.request);
    this.profiles = new ProfilesService(this.request);
    this.programmingLanguages = new ProgrammingLanguagesService(this.request);
    this.redirects = new RedirectsService(this.request);
    this.resume = new ResumeService(this.request);
    this.resumeTestResults = new ResumeTestResultsService(this.request);
    this.specializations = new SpecializationsService(this.request);
    this.system = new SystemService(this.request);
    this.testSuiteCategories = new TestSuiteCategoriesService(this.request);
    this.testSuites = new TestSuitesService(this.request);
    this.userAchievements = new UserAchievementsService(this.request);
    this.userLinkedProfiles = new UserLinkedProfilesService(this.request);
    this.userOAuthAccessGrants = new UserOAuthAccessGrantsService(this.request);
    this.users = new UsersService(this.request);
    this.userSegments = new UserSegmentsService(this.request);
    this.userStages = new UserStagesService(this.request);
    this.userTestResults = new UserTestResultsService(this.request);
    this.userWallets = new UserWalletsService(this.request);
    this.visits = new VisitsService(this.request);
  }
}
