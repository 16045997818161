/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Activity } from '../models/Activity';
import type { ActivityResource } from '../models/ActivityResource';
import type { ResourceLinks } from '../models/ResourceLinks';
import type { ResourceMeta } from '../models/ResourceMeta';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ActivitiesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Search for activities
   * @param include
   * @returns any OK
   * @throws ApiError
   */
  public postApiActivitiesSearch(include?: 'banners'): CancelablePromise<{
    data?: Array<ActivityResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/activities/search',
      query: {
        include: include,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Get a list of activities
   * @param include
   * @returns any OK
   * @throws ApiError
   */
  public getApiActivities(include?: 'banners'): CancelablePromise<{
    data?: Array<ActivityResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/activities',
      query: {
        include: include,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Create activity
   * @param include
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiActivities(
    include?: 'banners',
    requestBody?: Activity,
  ): CancelablePromise<{
    data?: ActivityResource;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/activities',
      query: {
        include: include,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Get activity
   * @param activity
   * @param include
   * @returns any OK
   * @throws ApiError
   */
  public getApiActivities1(
    activity: number,
    include?: 'banners',
  ): CancelablePromise<{
    data?: ActivityResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/activities/{activity}',
      path: {
        activity: activity,
      },
      query: {
        include: include,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
      },
    });
  }

  /**
   * Update activity
   * @param activity
   * @param include
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public patchApiActivities(
    activity: number,
    include?: 'banners',
    requestBody?: Activity,
  ): CancelablePromise<{
    data?: ActivityResource;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/activities/{activity}',
      path: {
        activity: activity,
      },
      query: {
        include: include,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Delete activity
   * @param activity
   * @param include
   * @returns any OK
   * @throws ApiError
   */
  public deleteApiActivities(
    activity: number,
    include?: 'banners',
  ): CancelablePromise<{
    data?: ActivityResource;
  }> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/activities/{activity}',
      path: {
        activity: activity,
      },
      query: {
        include: include,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
      },
    });
  }

  /**
   * Get activity context
   * @param slug
   * @returns any OK
   * @throws ApiError
   */
  public getActivitiesSlug(slug: string): CancelablePromise<{
    data?: ActivityResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/activity-context/{slug}',
      path: {
        slug: slug,
      },
      errors: {
        401: `Unauthenticated`,
      },
    });
  }

  /**
   * Get a list of suggested activities
   * @returns any OK
   * @throws ApiError
   */
  public getActivitiesSuggestions(): CancelablePromise<{
    data?: Array<ActivityResource>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/activities/suggestions',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
