/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ResumeTestResultsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Sync test results
   * @param resume
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public patchApiResumesTestResultsSync(
    resume: number,
    requestBody?: {
      resources?: Array<any>;
    },
  ): CancelablePromise<{
    attached?: Array<number>;
    detached?: Array<number>;
    updated?: Array<number>;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/resumes/{resume}/test-results/sync',
      path: {
        resume: resume,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Update pivot
   * @param resume
   * @param testResult
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public patchApiResumesTestResultsPivot(
    resume: number,
    testResult: number,
    requestBody?: {
      pivot?: {
        visible?: boolean;
      };
    },
  ): CancelablePromise<{
    updated?: Array<number>;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/resumes/{resume}/test-results/{test_result}/pivot',
      path: {
        resume: resume,
        test_result: testResult,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        404: `Resource not found`,
        422: `Validation error`,
      },
    });
  }
}
