/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResourceLinks } from '../models/ResourceLinks';
import type { ResourceMeta } from '../models/ResourceMeta';
import type { SegmentResource } from '../models/SegmentResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ActivitySegmentsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Search for segments
   * @param activity
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiActivitiesSegmentsSearch(
    activity: number,
    requestBody?: {
      filters?: Array<{
        type?: 'and' | 'or';
        field: 'name' | 'slug';
        operator: '<' | '<=' | '>' | '>=' | '=' | '!=' | 'like' | 'not like' | 'ilike' | 'not ilike' | 'in' | 'not in';
        value: string;
      }>;
    },
  ): CancelablePromise<{
    data?: Array<SegmentResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/activities/{activity}/segments/search',
      path: {
        activity: activity,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Get a list of segments
   * @param activity
   * @returns any OK
   * @throws ApiError
   */
  public getApiActivitiesSegments(activity: number): CancelablePromise<{
    data?: Array<SegmentResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/activities/{activity}/segments',
      path: {
        activity: activity,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }
}
