/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProgrammingLanguage } from '../models/ProgrammingLanguage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProgrammingLanguagesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get a list of supported programming languages
   * @returns any A list of supported programming languages
   * @throws ApiError
   */
  public getProgrammingLanguages(): CancelablePromise<{
    data?: Array<ProgrammingLanguage>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/programming_languages',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }
}
