/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConsentDocumentResource } from '../models/ConsentDocumentResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ConsentsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get a list of pending consents
   * @param companyId
   * @returns any OK
   * @throws ApiError
   */
  public getConsents(companyId: number): CancelablePromise<{
    data?: ConsentDocumentResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/consents',
      query: {
        company_id: companyId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Accept consents
   * @param companyId
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postConsents(
    companyId: number,
    requestBody?: {
      consents?: Array<{
        id?: number;
        accepted?: boolean;
      }>;
    },
  ): CancelablePromise<{
    accepted?: Array<number>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/consents',
      query: {
        company_id: companyId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `The exception is thrown when one of the required consents is not accepted.`,
        500: `Internal Server Error`,
      },
    });
  }
}
