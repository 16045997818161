/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivityResource } from '../models/ActivityResource';
import type { CurrencyEnum } from '../models/CurrencyEnum';
import type { EnrichedVideo } from '../models/EnrichedVideo';
import type { LanguageKnowledgeEnum } from '../models/LanguageKnowledgeEnum';
import type { PublicResumeResource } from '../models/PublicResumeResource';
import type { ResourceLinks } from '../models/ResourceLinks';
import type { ResourceMeta } from '../models/ResourceMeta';
import type { ResumeCertificate } from '../models/ResumeCertificate';
import type { ResumeCity } from '../models/ResumeCity';
import type { ResumeContact } from '../models/ResumeContact';
import type { ResumeContribution } from '../models/ResumeContribution';
import type { ResumeCountry } from '../models/ResumeCountry';
import type { ResumePositionPayload } from '../models/ResumePositionPayload';
import type { ResumeResource } from '../models/ResumeResource';
import type { ResumeTechnologyStackEnumTool } from '../models/ResumeTechnologyStackEnumTool';
import type { ResumeTechnologyStackTool } from '../models/ResumeTechnologyStackTool';
import type { ResumeUniversity } from '../models/ResumeUniversity';
import type { SalaryPeriodEnum } from '../models/SalaryPeriodEnum';
import type { WorkFeatureEnum } from '../models/WorkFeatureEnum';
import type { WorkingEnvironmentEnum } from '../models/WorkingEnvironmentEnum';
import type { WorkingScheduleEnum } from '../models/WorkingScheduleEnum';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ResumeService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get resume by hash
   * @param hash
   * @param signature
   * @returns any OK
   * @throws ApiError
   */
  public getApiUsersLookupResume(
    hash: string,
    signature?: string,
  ): CancelablePromise<{
    data?: PublicResumeResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/users/lookup/{hash}/resume',
      path: {
        hash: hash,
      },
      query: {
        signature: signature,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get resume
   * @returns any OK
   * @throws ApiError
   */
  public getApiResume(): CancelablePromise<{
    data?: ResumeResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/resume',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Delete resume
   * @returns any OK
   * @throws ApiError
   */
  public deleteApiResume(): CancelablePromise<{
    data?: ResumeResource;
  }> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/resume',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get resume context
   * @returns any OK
   * @throws ApiError
   */
  public getApiResumeContext(): CancelablePromise<{
    data?: ActivityResource;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/resume/context',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Import resume
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiResumeImport(requestBody?: { url?: string | null; file?: Blob | null }): CancelablePromise<{
    data?: ResumeResource;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/resume/import',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Translate resume
   * @param resume
   * @returns any OK
   * @throws ApiError
   */
  public postApiResumeTranslation(resume: number): CancelablePromise<{
    data?: ResumeResource;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/resume/{resume}/translation',
      path: {
        resume: resume,
      },
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Update personal information
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public patchApiResumePersonal(requestBody?: {
    first_name?: string;
    last_name?: string | null;
    email?: string;
    phone?: string;
    /**
     * Date in Y-m-d format
     */
    birthday?: string;
    contacts?: Array<ResumeContact> | null;
  }): CancelablePromise<{
    data?: ResumeResource;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/resume/personal',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Update photo
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiResumePersonalPhoto(requestBody?: { photo: Blob }): CancelablePromise<{
    data?: ResumeResource;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/resume/personal/photo',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Remove photo
   * @returns any OK
   * @throws ApiError
   */
  public deleteApiResumePersonalPhoto(): CancelablePromise<{
    data?: ResumeResource;
  }> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/resume/personal/photo',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Update general information
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public patchApiResumeGeneral(requestBody?: {
    position?: string;
    about?: string | null;
    min_salary?: number;
    desired_salary?: number;
    desired_salary_visible?: boolean;
    salary_currency?: CurrencyEnum;
    salary_period?: SalaryPeriodEnum;
    work_environment?: Array<WorkingEnvironmentEnum>;
    work_schedule?: Array<WorkingScheduleEnum>;
    city?: ResumeCity;
    relocation_to_diff_country?: boolean;
    relocation_countries?: Array<ResumeCountry> | null;
    relocation_to_diff_city?: boolean;
    relocation_cities?: Array<ResumeCity> | null;
    has_management_experience?: boolean;
    management_experience?: number | null;
    management_experience_description?: string;
    languages_knowledge?: Array<{
      /**
       * Locale in ISO 639-1 format
       */
      id?: string;
      level?: LanguageKnowledgeEnum;
    }>;
  }): CancelablePromise<{
    data?: ResumeResource;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/resume/general',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Update technology stack
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public patchApiResumeTechnologyStack(requestBody?: {
    specialization_areas?: Array<ResumeTechnologyStackEnumTool>;
    specializations?: Array<ResumeTechnologyStackEnumTool> | null;
    programming_languages?: Array<ResumeTechnologyStackTool> | null;
    technologies?: Array<ResumeTechnologyStackTool> | null;
    databases?: Array<ResumeTechnologyStackTool> | null;
  }): CancelablePromise<{
    data?: ResumeResource;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/resume/technology_stack',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Update contributions
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public patchApiResumeContribution(requestBody?: { contributions?: Array<ResumeContribution> }): CancelablePromise<{
    data?: ResumeResource;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/resume/contribution',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Update preferences
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public patchApiResumePreferences(requestBody?: { preferable_features?: Array<WorkFeatureEnum> }): CancelablePromise<{
    data?: ResumeResource;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/resume/preferences',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Update experience
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public patchApiResumeExperience(requestBody?: { positions?: Array<ResumePositionPayload> }): CancelablePromise<{
    data?: ResumeResource;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/resume/experience',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Update education
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public patchApiResumeEducation(requestBody?: {
    universities?: Array<ResumeUniversity>;
    certificates?: Array<ResumeCertificate>;
  }): CancelablePromise<{
    data?: ResumeResource;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/resume/education',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Progress through test results
   * @returns any OK
   * @throws ApiError
   */
  public patchApiResumeTestResults(): CancelablePromise<{
    data?: ResumeResource;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/resume/test-results',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Update video introduction
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public patchApiResumeVideoIntroduction(requestBody?: {
    /**
     * Link to a YouTube or Vimeo video
     */
    video_url?: string;
  }): CancelablePromise<{
    data?: ResumeResource;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/resume/video-introduction',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Get video introduction preview
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiResumeVideoIntroductionPreview(requestBody?: {
    /**
     * Link to a YouTube or Vimeo video
     */
    video_url?: string;
  }): CancelablePromise<{
    data?: EnrichedVideo;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/resume/video-introduction/preview',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Toggle visibility
   * @returns any OK
   * @throws ApiError
   */
  public patchApiResumeVisibility(): CancelablePromise<{
    data?: PublicResumeResource;
  }> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/resume/visibility',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }

  /**
   * Search for resumes
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public postApiIntegrationsResumeSearch(requestBody?: {
    filters?: Array<{
      type?: 'and' | 'or';
      field: 'updated_at';
      operator?:
        | '<'
        | '<='
        | '>'
        | '>='
        | '='
        | '!='
        | 'like'
        | 'not like'
        | 'ilike'
        | 'not ilike'
        | 'in'
        | 'not in'
        | 'all in'
        | 'any in';
      value: string;
    }>;
  }): CancelablePromise<{
    data?: Array<ResumeResource>;
    links?: ResourceLinks;
    meta?: ResourceMeta;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/integrations/resume/search',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthenticated`,
        403: `Unauthorized`,
      },
    });
  }
}
